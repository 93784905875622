<template>
  <tr colspan="3">
    <td class="table-td border-r border-opacity-20">#</td>
    <td class="table-td border-r border-opacity-20">
      <vehicleSelect
        v-model="vehicle"
        :optList="getFilterVehicle"
        :load="vehicleLoad"
        :sorted="activeList != null || activeList <= vehicleList ? true : false"
        :products="products"
        :customers="customers"
        :order="order"
        :isBulk="activeList != null || activeList <= vehicleList ? true : false"
        :selectedVehicles="selectedVehicles"
      />
    </td>
    <td class="table-td border-r border-opacity-20">
      {{ vehicle.address }}
    </td>
    <td class="table-td border-r border-opacity-20">
      <span v-if="cost"> {{ cost }} </span>
    </td>
    <td class="text-center">
      <button type="button" class="process-btn" @click="addItem()">
        <i class="fas fa-plus mr-1.5"></i> Ekle
      </button>
    </td>
  </tr>
</template>

<script>
import { calculateDistancePriority } from "../../../utils/recomendation";
import { calculator } from "../../../utils/cost";

import vehicleSelect from "../../../views/operations/orders/components/vehicle-select.vue";

export default {
  name: "new-vehicle-row",
  props: [
    "order",
    "products",
    "customers",
    "vehicleList",
    "activeList",
    "selectedVehicles",
  ],
  components: {
    vehicleSelect,
  },
  data() {
    return {
      vehicle: "",
      vehicleLoad: false,
      dorse: "",
      driverName: "",
      driverTC: "",
      capacity: "",
      cost: null,
    };
  },
  methods: {
    addItem() {
      this.$emit("addItem", this.vehicle);
      this.vehicle = "";
    },
    async getCost(vehicle) {
      const montlyKilometer = calculateDistancePriority(
        {
          startLat: Number(
            this.order.outpointLat
              ? this.order.outpointLat
              : this.order.fillingLatitude
          ),
          startLng: Number(
            this.order.outpointLng
              ? this.order.outpointLng
              : this.order.fillingLongitude
          ),
        },
        { latitude: vehicle.latitude, longitude: vehicle.longitude }
      );
      const cost = await calculator(
        this.$store.state.userData.token,
        1,
        1,
        vehicle,
        this.order,
        montlyKilometer,
        0,
        true,
        true,
        1,
        montlyKilometer / 50
      );
      this.cost = cost.total | "";
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList?.map((item) => {
        // if (item.vehicle === "34GDR691") {
        //   console.log("vehicle:", item.vehicle);
        //   console.log("id:", item.id);
        //   console.log("driverName:", item.driverName);
        //   console.log("dorse:", item.dorse);
        //   console.log("driverTC:", item.driverTC);
        //   console.log("latitude:", item.latitude);
        //   console.log("longitude:", item.longitude);
        //   console.log("capacity:", item.capacity);
        //   console.log("orderNos:", item.orderNos);
        //   console.log("romorkType:", item.romorkType);
        //   console.log("lastProduct:", item?.lastProduct);
        //   console.log("address:", item.address);
        //   console.log("isAvailable:", item.isAvailable);
        //   console.log("inspectionStartDate:", item?.inspectionStartDate);
        //   console.log("inspectionEndDate:", item?.inspectionEndDate);
        //   setTimeout(() => {
        //     console.log("////////////////////////");
        //   }, 100);
        // }

        // if (item.vehicle === "01AHE697") {
        //   setTimeout(() => {
        //     console.log("vehicle:", item.vehicle);
        //     console.log("id:", item.id);
        //     console.log("driverName:", item.driverName);
        //     console.log("dorse:", item.dorse);
        //     console.log("driverTC:", item.driverTC);
        //     console.log("latitude:", item.latitude);
        //     console.log("longitude:", item.longitude);
        //     console.log("capacity:", item.capacity);
        //     console.log("orderNos:", item.orderNos);
        //     console.log("romorkType:", item.romorkType);
        //     console.log("lastProduct:", item?.lastProduct);
        //     console.log("address:", item.address);
        //     console.log("isAvailable:", item.isAvailable);
        //     console.log("inspectionStartDate:", item?.inspectionStartDate);
        //     console.log("inspectionEndDate:", item?.inspectionEndDate);
        //   }, 1000);
        // }
        return {
          name: item.vehicle,
          id: item.id,
          driverName: item.driverName,
          dorse: item.dorse,
          driverTC: item.driverTC,
          latitude: item.latitude,
          longitude: item.longitude,
          capacity: item.capacity,
          orderNos: item.orderNos,
          romorkType: item.romorkType,
          isRent: item?.haveType,
          lastProduct: item?.lastProduct,
          address: item.address,
          isAvailable: item.isAvailable,
          vehicleInspectionStartDate: item?.vehicleInspectionStartDate,
          vehicleInspectionEndDate: item?.vehicleInspectionEndDate,
          inspectionStartDate: item?.inspectionStartDate,
          inspectionEndDate: item?.inspectionEndDate,
          vehicleAdrStartDate: item?.vehicleAdrStartDate,
          vehicleAdrEndDate: item?.vehicleAdrEndDate,
          dorseAdrStartDate: item?.dorseAdrStartDate,
          dorseAdrEndDate: item?.dorseAdrEndDate,
          transportPurpose: item?.transportPurpose,
          fleetCustomers: item?.fleetCustomers,
          fleetId: item?.fleetId,
        };
      });
    },
  },
  watch: {
    vehicle(val) {
      this.getCost(val);
    },
  },
};
</script>
