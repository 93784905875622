var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full bg-gray-200 dark:bg-gray-900"},[_c('SideBarItem',{on:{"selected":(r) => ((_vm.selectedFleet = r), (_vm.selectedGroup = 0)),"selectedGroup":(r) => (_vm.selectedGroup = r),"selectedItem":(val) => _vm.setPlaqueSelected(val),"closeModal":() => _vm.resetItemDetail(),"changeIntervention":(val) => (_vm.isIntervention = val),"changeInterventionList":(val) => (_vm.interventionList = val)}}),_c('ProductSideBarItem',{ref:"prodDetail",attrs:{"detail":_vm.detail},on:{"backMenu":_vm.backProduct,"closeModal":() => _vm.resetItemDetail(),"selectedActiveDetail":(val) => (_vm.selectedActiveDetail = val)}}),_c('FilterPanel',{ref:"filterPanel",attrs:{"detail":_vm.filter},on:{"change":(r) => _vm.getFilterAll(r)}}),(_vm.hoverList.length)?_c('GroupDetailPanel',{attrs:{"List":_vm.hoverList},on:{"close":() => _vm.resetItemDetail(),"selected":(val) => _vm.setPlaqueSelected(val)}}):_vm._e(),_c('detailModal',{attrs:{"detailList":_vm.markers}}),_c('div',{staticClass:"fixed right-64 z-10 md:w-4/12 xl:w-3/12 flex items-center",staticStyle:{"right":"260px","top":"128px"}},[_c('button',{staticClass:"px-2 ml-2 h-10 w-16 rounded-lg justify-center flex items-center",class:_vm.showFactories === true ? 'bg-black ' : 'bg-white',on:{"click":function($event){return _vm.toggleFactories()}}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":_vm.showFactories === false
            ? require('@/assets/marker/factoryDark.png')
            : require('@/assets/marker/factoryLight.png')}})]),_c('button',{staticClass:"ml-2 mr-2 bg-white rounded-md shadow-xl w-14 h-10 px-2",on:{"click":() => _vm.$modal.show('planning-state-modal')}},[_c('i',{staticClass:"fas fa-list"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"w-full h-10 px-2 border-b-2 rounded focus:outline-none bg-white",attrs:{"type":"search","placeholder":"Aramak için yazınız..."},domProps:{"value":(_vm.search)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.performSearchOnEnter.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('button',{staticClass:"px-2 ml-2 h-10 w-16 rounded-lg justify-center flex items-center",class:_vm.$store.state.mapView == true ? 'bg-black ' : 'bg-white',on:{"click":function($event){return _vm.mapstatus()}}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":_vm.$store.state.mapView == false
            ? require('@/assets/marker/mapstatus.svg')
            : require('@/assets/marker/mapstatus_white.svg')}})]),_c('button',{staticClass:"mx-2 bg-white h-10 rounded-md shadow-xl w-16",on:{"click":() => _vm.$refs.filterPanel.showModal()}},[_c('i',{staticClass:"fas fa-filter"})])]),_c('vehicleFilter',{on:{"change":(val) => (_vm.selectedVehicleTypes = val)}}),(_vm.load)?_c('div',{staticClass:"w-full z-20 fixed flex top-0 h-screen bg-black bg-opacity-70 items-center justify-center",staticStyle:{"left":"0%"}},[_c('i',{staticClass:"fas fa-spinner fa-spin fa-2x text-white"})]):_vm._e(),_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"100vh !important"},attrs:{"max-zoom":14,"center":_vm.position,"zoom":_vm.zoomLevel,"map-type-id":_vm.$store.state.mapView ? 'hybrid' : 'roadmap',"options":{
      minZoom: 3,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    }}},[(!_vm.selectedItem)?_c('GmapCluster',{on:{"click":(val) => _vm.handleClusterMouseOver(val, 'vehicle')}},_vm._l((_vm.filterList()),function(m,index){return _c('GmapMarker',{key:index,attrs:{"title":m.title,"label":{
          text: m.title,
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
          speed: m.speed,

          onOperation: m.planning.state >= 5 ? 1 : 0,
        },"position":m.position,"icon":m.icon,"rotation":"30"},on:{"click":() => _vm.showItemDetail(m, 'vehicle')}})}),1):_vm._e(),(!_vm.selectedItem && _vm.showFactories)?_c('GmapCluster',{attrs:{"styles":_vm.clusterStyles},on:{"click":(val) => _vm.handleClusterMouseOver(val, 'factory')}},_vm._l((_vm.vectorMarkers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"title":m.title,"label":{
          text: m.title,
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-green-700 rounded tracking-widest',
          fontSize: '8.5px',
        },"position":m.position,"icon":m.icon,"rotation":"30"}})}),1):_vm._e(),(_vm.selectedItem)?_c('GmapMarker',{attrs:{"title":_vm.selectedItem.title,"label":{
        text: _vm.selectedItem.title,
        color: 'white',
        className:
          'absolute bottom-3 -right-8 text-xs  px-2 text-xs font-bold bg-black rounded',
        fontSize: '8.5px',
      },"position":_vm.selectedItem.position,"icon":_vm.selectedItem.icon},on:{"click":() => _vm.showItemDetail(_vm.selectedItem)}}):_vm._e(),(
        _vm.selectedItem &&
        _vm.selectedActiveDetail.targetPoint.lat &&
        _vm.selectedActiveDetail.targetPoint.lng
      )?_c('GmapMarker',{attrs:{"title":_vm.selectedActiveDetail.state <= 3
          ? 'Yükleme Noktası'
          : _vm.selectedActiveDetail.customer,"label":{
        text:
          _vm.selectedActiveDetail.state <= 3
            ? 'Yükleme Noktası'
            : _vm.selectedActiveDetail.customer,
        color: 'white',
        className:
          'absolute top-4 px-2 rounded left-0 text-xs  px-2 text-xs font-bold bg-red-500',
        fontSize: '12px',
      },"position":_vm.selectedActiveDetail.targetPoint}}):_vm._e(),(_vm.selectedActiveDetail.polyline && _vm.selectedItem)?_c('GmapPolyline',{attrs:{"path":_vm.selectedActiveDetail.polyline,"options":{ strokeColor: '#C43A3A' }},on:{"update:path":function($event){return _vm.$set(_vm.selectedActiveDetail, "polyline", $event)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }