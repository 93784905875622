<template>
  <div class="w-full pb-4 mt-3 border-b">
    <h4 class="mb-2 border-b pb-2 font-bold text-sm">GÜMRÜKLEME NOKTALARI</h4>
    <form
      @submit.prevent="save"
      ref="subFormItem"
      v-if="!hiddenInsert"
      class="w-full items-center justify-center max-h-60 overflow-y-auto"
    >
      <div class="w-full flex">
        <div class="w-2/12 mt-3">
          <diffInput
            step="1"
            min="1"
            max="40"
            type="number"
            :hideContainer="true"
            title="Sıra"
            :disabled="true"
            v-model="no"
            required
          />
        </div>
        <div class="w-10/12 mt-3 ml-2">
          <gmapInputVue
            @change="(val) => (position = val)"
            :defaultAdress="position.adress"
            title="Konum"
            :hideMap="true"
            :hideContainer="true"
          />
        </div>
      </div>
      <div class="w-full flex">
        <div class="w-10/12 mt-3">
          <diffInput
            step="1"
            min="1"
            type="numberLength"
            :valCount="25"
            title="Miktar"
            v-model="amount"
            :hideContainer="true"
            required
          />
        </div>
        <div class="w-5/12 mt-3 ml-2" v-if="!isHiddenDate">
          <diffInput
            title="Tarih"
            type="datetime-local"
            v-model="date"
            :hideContainer="true"
            :min="minDate"
            :max="maxDate"
            required
          />
        </div>
        <div class="w-2/12 mt-3 ml-2 text-right pr-6 py-1 border">
          <button
            type="submit"
            class="text-green-500 mr-2 py-1 px-2 rounded border border-green-500 text-sm"
          >
            <i class="fas fa-check"></i>
          </button>
          <button
            type="button"
            class="text-red-500 py-1 px-2 rounded border border-red-500 text-sm"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </form>

    <div
      class="w-full border-b py-2 px-2 my-2"
      v-if="List.length > 0 && List.some((item) => item.date !== null)"
      style="background-color: #3a3a3a"
    >
      <div
        class="flex items-center justify-center border-t py-2"
        v-for="(item, index) in List"
        :key="index"
      >
        <div class="w-2/12 border-r text-white">{{ item.no }}.Numara</div>
        <div class="w-7/12 border-r pl-3 text-sm text-white">
          {{ item.position.adress }}
        </div>
        <div class="w-2/12 border-r pl-3 text-white">{{ item.amount }} TON</div>
        <div class="w-2/12 ml-2 text-center">
          <button
            v-if="!hiddenInsert"
            type="button"
            @click="removeItem(index)"
            class="text-red-500 py-1 px-2 rounded border border-red-500 text-sm"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//npm
import moment from "moment";

//global components
import gmapInputVue from "@/components/general/gmapInput.vue";

//networking
import diffInput from "@/components/general/diffInput.vue";

//local components
import { calculateDistancePriority } from "@/utils/recomendation.js";

export default {
  props: ["value", "maxDate", "minDate", "hiddenInsert", "isHiddenDate"],
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    diffInput,
    gmapInputVue,
  },
  data() {
    return {
      List: [],
      tonnage: 0,
      no: 1,
      name: 0,
      position: { lat: 0, lng: 0, adress: "" },
      amount: "",
      date: moment().format("YYYY-MM-DD HH:mm"),
    };
  },
  methods: {
    save() {
      try {
        let text = "",
          isError = false;

        if (this.no <= 0) {
          text = "GÜMRÜKLEME NOKTALARINDA SIRALAMA 1 DEN BAŞLAMALIDIR!";
          isError = true;
        }

        if (this.amount <= 0) {
          text = "GÜMRÜKLEME NOKTALARINDA MİKTAR 0 DAN BÜYÜK OLMALIDIR!";
          isError = true;
        }

        if (this.amount > 26) {
          text = "GÜMRÜKLEME NOKTALARINDA MİKTAR 26 DEN KÜÇÜK OLMALIDIR!";
          isError = true;
        }

        if (!this.date) {
          text = "Tarih Alanı boş bırakılamaz!";
          isError = true;
        }

        if (!this.position.adress) {
          text = "Ara noktanın konumunu seçmelisiniz!";
          isError = true;
        }

        if (this.isHiddenDate != true) {
          if (moment(this.date).diff(this.maxDate, "minutes") > 1) {
            text =
              "Ara nokta için planlanan süre operasyonunun tamamlanma süresinden büyük olamaz!";
            isError = true;
          }

          if (moment(this.date).diff(this.minDate, "minutes") < 1) {
            text =
              "Ara nokta için planlanan süre operasyonun başlangıç süresinden küçük olamaz!";
            isError = true;
          }

          if (isError) {
            this.$swal.fire({
              icon: "warning",
              title: "UYARI!",
              text: text,
              showConfirmButton: true,
              confirmButtonText: "TAMAM",
            });
            return;
          }

          if (this.List.length > 0) {
            const item = this.List[this.List.length - 1];
            const oldItemDate = new moment(item.date);
            const distance =
              calculateDistancePriority(
                {
                  startLat: item.position.lat,
                  startLng: item.position.lng,
                },
                {
                  latitude: this.position.lat,
                  longitude: this.position.lng,
                }
              ) / 1000; // metre to km

            const diff = moment(this.date).diff(oldItemDate, "minutes");

            const targetHour = (distance / 60) * 60;

            if (diff < 0) {
              this.swalBox(
                "warning",
                "UYARI",
                "Lütfen dikkat edin, daha önceden belirlenmiş teslimat tarihinden önce bir teslimat yapmamız mümkün değildir.",
                false,
                "Tamam"
              );
              return;
            } else if (diff < targetHour) {
              this.swalBox(
                "warning",
                "UYARI",
                `Lütfen dikkat edin, daha önceden belirlenmiş teslimat minimum ${moment(
                  oldItemDate
                )
                  .add(targetHour, "minutes")
                  .format("LLL")} önce bir teslimat yapmamız mümkün değildir.`,
                false,
                "Tamam"
              );
              return;
            }
          }
        }

        this.tonnage += parseFloat(this.amount);

        // Kullanılmıyor
        // // if (this.tonnage > this.maxTonnage - 1) {
        // //   alert("ARA NOKTALARA BIRAKILACAK ÜRÜN MİKTARI TOPLAM MİKTARDAN FAZLA OLAMAZ");
        // //   this.tonnage -= parseFloat(this.amount);
        // //   return;
        // // }
        this.List.push({
          no: this.no,
          name: this.name,
          position: this.position,
          amount: this.amount,
          date: this.date,
          isWaypoint: true,
        });

        this.no = this.List.length + 1;
        this.name = "";
        this.position = { adress: "" };
        this.amount = "";
        this.date = moment().format("YYYY-MM-DD HH:mm");
      } catch (error) {
        console.log(error);
        this.swalBox(
          "warning",
          "UYARI",
          "Ara nokta silinemedi!",
          false,
          "Tamam"
        );
      }
    },
    removeItem(val) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "ARA NOKTAYI SİLMEK İSTEDİĞİNİZE EMİN MİSİNİZ",
        true,
        this.$t("general.yes"),
        this.$t("general.no")
      ).then((acc) => {
        if (acc.isConfirmed) {
          try {
            this.tonnage -= parseFloat(this.List[val].amount);
            this.List.splice(val, 1);

            setTimeout(() => {
              this.List = this.List.map((item, index) => {
                return {
                  no: index + 1,
                  name: item.name,
                  position: item.position,
                  amount: item.amount,
                  date: item.date,
                };
              });

              this.no = this.List.length + 1;
            }, 50);
          } catch (error) {
            console.log(error);
            this.swalBox(
              "warning",
              "UYARI",
              "Ara nokta silinemedi!",
              false,
              "Tamam"
            );
          }
        }
      });
    },
  },
  created() {
    this.List = [];
  },
  filters: {
    dateFormatter(val) {
      return moment(val).format("LLLL");
    },
  },
  watch: {
    value(val) {
      this.List = val;
    },
    List(val) {
      this.$emit("change", val);
    },
  },
};
</script>
