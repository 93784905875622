<template>
  <div class="fixed p-2 left-0 top-26 z-50" style="z-index: 20 !important">
    <button
      v-for="item in options"
      :key="item.id"
      class="px-4 py-2 m-1 rounded"
      :class="selected.includes(item.id) ? 'bg-red-600 text-white' : 'bg-white'"
      @click="selectedOption(item.id)"
    >
      {{ item.name }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: [1],
      options: [
        { name: "Takım", id: 1 },
        { name: "Çekici",id: 2},
        { name: "Dorse", id: 3 },
      ],
    };
  },
  methods:{
    selectedOption(val){
        if(this.selected.includes(val)){
          this.selected = this.selected.filter(item => item!== val);
        } else {
          this.selected.push(val);
        }
  
    }
  },
  watch:{
    selected(val){
        this.$emit('change', val);
    }
  }
};
</script>