<template>
  <div class="w-full pb-4 mt-3 border-b">
    <h4 class="mb-2 border-b pb-2 font-bold text-sm" >
      SINIR NOKTALARI
    </h4>
    <form
      @submit.prevent="save"
      ref="subFormItem"
      v-if="!hiddenInsert"
      class="w-full items-center justify-center "
    >
      <div class="w-full flex">
        <div class="w-2/12 mt-3">
          <diffInput
            step="1"
            min="1"
            max="40"
            type="number"
            :hideContainer="true"
            title="Sıra"
            :disabled="true"
            v-model="no"
            required
          />
        </div>
        <div class="w-10/12 mt-3 ml-2 z-40">
          <custompointSelect  
            @changeObject="val=>customPoint=val"
            title="Sınır Kapısı"
            :hideMap="true"
            :hideContainer="true"
            :selected-list="value"
          />
        </div>
        <div class="w-2/12 mt-3 ml-2 text-right pr-6 py-1 border">
          <button
            type="submit"
            class="text-green-500 mr-2 py-1 px-2 rounded border border-green-500 text-sm"
          >
            <i class="fas fa-check"></i>
          </button>
          <button
            type="button"
            class="text-red-500 py-1 px-2 rounded border border-red-500 text-sm"
          >
            <i class="fas fa-trash"></i>
          </button>
      </div>
    </div>
    </form>

    <div
      class="w-full border-b py-2 px-2 my-2"
      v-if="List.length > 0 && List.some((item) => item.date !== null)"
      style="background-color: #3a3a3a"
    >
      <div
        class="flex items-center justify-center border-t py-2"
        v-for="(item, index) in List"
        :key="index"
      >
        <div class="w-2/12 border-r text-white text-xs">{{ item.no }}.Numara</div>
        <div class="w-7/12 border-r pl-3 text-sm text-white">
          {{ item.name }}
        </div>
        <div class="w-2/12 ml-2 text-center">
          <button
            v-if="!hiddenInsert"
            type="button"
            @click="removeItem(index)"
            class="text-red-500 py-1 px-2 rounded border border-red-500 text-sm"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//npm
import moment from "moment";

//global components
import custompointSelect from "./custompoint-select.vue";

//networking
import diffInput from "@/components/general/diffInput.vue";



export default {
  props: [
    "value",
    "maxDate",
    "minDate",
    "hiddenInsert",
    "isHiddenDate",
  ],
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    diffInput,
    custompointSelect,
  },
  data() {
    return {
      List: [],
      no: 1,
      customPoint:null,
      date: moment().format("YYYY-MM-DD HH:mm"),
    };
  },
  methods: {
    save() {
      try {
        if (this.no <= 0) {
          text = "GÜMRÜKLEME NOKTALARINDA SIRALAMA 1 DEN BAŞLAMALIDIR!";
          isError = true;
        }
        this.List.push({
          no: this.no,
          id:this.customPoint?.id,
          name: this.customPoint?.name,
          position:{
            lat: this.customPoint?.latitude,
            lng: this.customPoint?.longitude,
          },
          isWaypoint: true
        });

        this.no = this.List.length + 1;
      } catch (error) {
        console.log(error);
      }
    },
    removeItem(val) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "SINIR NOKTASINI SİLMEK İSTEDİĞİNİZE EMİN MİSİNİZ",
        true,
        this.$t("general.yes"),
        this.$t("general.no")
      ).then((acc) => {
        if (acc.isConfirmed) {
          try {
            this.tonnage -= parseFloat(this.List[val].amount);
            this.List.splice(val, 1);

            setTimeout(() => {
              this.List = this.List.map((item, index) => {
                return {
                  no: index + 1,
                  name: item.name,
                  position: item.position,
                };
              });

              this.no = this.List.length + 1;
            }, 50);
          } catch (error) {
            console.log(error);
            this.swalBox(
              "warning",
              "UYARI",
              "SINIR NOKTASI SİLİNEMEDİ!",
              false,
              "Tamam"
            );
          }
        }
      });
    },
  },
  created() {
    this.List = [];
  },
  filters: {
    dateFormatter(val) {
      return moment(val).format("LLLL");
    },
  },
  watch: {
    value(val) {
      this.List = val;
    },
    List(val) {
      this.$emit("change", val);
    },
  },
};
</script>
