<template>
  <modal
    name="approval-files-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
    style="z-index: 99 !important"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">SİPARİŞ EVRAK DOĞRULAMA</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('approval-files-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full mt-4">
        <diffInput
          type="file"
          :required="true"
          @getFile="(val) => (beyanname = val)"
          title="Beyanname"
        />
      </div>

      <div class="w-full mt-4">
        <diffInput
          type="file"
          :required="true"
          @getFile="(val) => (atr = val)"
          title="ATR / EUR1"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          type="file"
          :required="true"
          @getFile="(val) => (cekilist = val)"
          title="Çeki Listesi"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          type="file"
          :required="true"
          @getFile="(val) => (invoice = val)"
          title="Invoice (Fatura) "
        />
      </div>

      <div class="w-full mt-4">
        <diffInput
          type="textarea"
          maxlength="500"
          v-model="textarea"
          title="Note "
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

import axios from "axios";
import { planning } from "@/networking/urlmanager";

export default {
  name: "approval-files-modal",
  props: ["rowId"],
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      beyanname: null,
      cekilist: null,
      invoice: null,
      atr: null,
      textarea: "",

      note: "",
      load: false,
    };
  },
  methods: {
    save() {
      this.load = true;

      let formData = new FormData();

      // Tüm dosyaları files array'i içinde gönder
      const files = [
        this.beyanname,
        this.atr,
        this.cekilist,
        this.invoice,
      ].filter((file) => file !== null);

      files.forEach((file, index) => {
        formData.append(`files[${index}][value]`, file);
        formData.append(`files[${index}][type]`, "file");
      });

      // Diğer alanları ekle
      formData.append("planId", this.rowId);
      formData.append("isBulk", 0);

      if (this.note) {
        formData.append("note", this.note);
      }

      axios
        .post(planning.setApprovalFiles, formData, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("approval-files-modal");
          });
        })
        .catch((err) => {
          console.log("err ", err.response);
          this.errorBox(err.response);
          this.load = false;
        });
    },

    resetForm() {
      this.beyanname = null;
      this.cekilist = null;
      this.invoice = null;
      this.atr = null;
      this.note = "";
      this.load = false;
    },
  },
};
</script>

<style></style>
