<template>
  <div class="fixed xl:top-24 top-0 h-screen xl:h-full w-full bg-white py-5 pl-6 pr-5 shadow xl:w-56"
    style="z-index: 21 !important">

    <!-- Header -->
    <div class="w-full border-b border-opacity-5 py-2 relative flex justify-between border-black items-center">
      <h5 class="text-xs font-bold">GRUP DETAYI</h5>

      <button @click="$emit('close', true)">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <!-- Header -->

    <!-- Body -->

    <div class="w-full py-2 overflow-y-scroll" style="height: 80vh !important">
      <div
        class="w-full py-3 select-none cursor-pointer px-2 my-2 text-xs rounded bg-gray-100 shadow hover:bg-gray-300 duration-200"
        v-for="(item, index) in List" :key="index" @click="getDetail(item)">

        <div class="w-full flex items-center justify-between">
          <p class="w-7/12"> {{ item.label.text }} </p>

          <p v-if="item.label.speed >=80"
          class="text-xl font-bold text-red-600"
            title="Aracınızın şu anki hızı 80km/s üzerine çıkmıştır">
              !
          </p>
          <div v-if="item.label.speed > 0 && item.label.onOperation === 1"
            title="Aracınızın üzerinde iş var ve aracınız haraket ediyor">
            <i class="fas fa-map-marker text-green-600 "></i>
          </div>
          <div v-if="item.label.speed === 0" title="Aracınız haraket etmiyor">
            <i class="fas fa-map-marker text-red-600"></i>
          </div>
          <div v-if="item.label.onOperation === 0 && item.label.speed > 0"
            title="Aracınızın üzerinde iş yok ve Haraket ediyor">
            <i class="fas fa-map-marker text-blue-600 "></i>
          </div>

        </div>
      </div>
    </div>

    <!-- Body -->
  </div>
</template>

<script>
export default {
  name: "vehicle-tracking",
  props: ["List"],
  methods: {
    getDetail(item) {
      this.$emit("selected", item.label.text);
    },
  },
};
</script>
