<template>
  <diffInput
    :loading="load"
    @asyncFind="val=>asyncFind(val)"
    :manuelSearch="true"
    type="select"
    :title="title ? title : ''"
    :optList="getList"
    :placeholder="placeholder"
    v-model="selected"
    :lightDark="lightDark"
    :hideContainer="hideContainer"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { routeEngine } from "@/networking/routeEngine.js";
import Fuse from "fuse.js";
export default {
  name: "custompoint-input",
  props: [
    "value",
    "title",
    "lightDark",
    "toggleSelectAll",
    "hideContainer",
    "placeholder",
    "selectedList",
  ],
  components: {
    diffInput,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: 0,
      fuse: null,
      filterList:[]
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    getAll() {
      this.load = false;
      axios
        .get(routeEngine.getCustomPoints, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          const dataList = result.data.data.map((item) => {
            return {
              ...item,
              name: item.country + " / " + item.name,
            };
          });

          this.List = dataList.length > 0 ? dataList : [];
          this.filterList =dataList.length > 0 ? dataList : [];

          this.fuse = new Fuse(this.List, {
            keys: ["name"],
            includeScore: false,
            threshold: 0.4,
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    asyncFind(val) {
      if (!this.fuse) return;
      if (!val) {
        this.filterList = [...this.List];
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results.map((result) => result.item);
      this.filterList = newFilterList;
    },
  },
  created() {
    this.selected = this.value;
    this.getAll();
  },
  computed: {
    getList() {
      return this.filterList?.filter(
        (r) => !this.selectedList?.find((a) => a.id == r.id)
      );
    },
  },
  watch: {
    selected(val) {
      this.$emit("change", val);
      const findItem = this.List?.find((r) => r.id == val);

      if (findItem) this.$emit("changeName", findItem.name);
      if (findItem) this.$emit("changeObject", findItem);
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
