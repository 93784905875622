<template>
  <modal name="order-detail-modal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '60%'">
    <div class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200">
      <div class="md:flex items-center justify-center w-full mt-4">
        <div class="w-full text-left">
          <div class="w-full md:flex items-center justify-between">
            <div class="w-3/12">
              <h4 class="inline-block w-8/12">SİPARİŞ DETAYI</h4>
            </div>
          </div>
        </div>
        <div class="flex gap-4">        
          <button class="p-2 w-1/12" @click="$modal.hide('order-detail-modal')">
          <i class="fas fa-times float-right"></i>
        </button>
        </div>
      </div>
    </div>
    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
          <tr>
            <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 200px !important">
              Ürün Türü
            </th>
            <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 100px !important">
              Tonaj
            </th>
            <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 200px !important">
              Birim Türü
            </th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
          <tr v-for="item in detail" :key="item.id" >
            <td class="table-td border-r border-opacity-20">
              {{ item.productType }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.tonnage }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.unitType }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
 
  </modal>
</template>

<script>
//global components


export default {
  props: ["detail"],


};
</script>
