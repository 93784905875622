<template>
  <div class="relative">
    <div v-if="load" class="p-4 text-center">
      <i class="fas fa-spinner fa-spin"></i>
      {{ $t("operations.planning.calculatingRoute") }}
    </div>
    <button
      v-if="!load"
      type="button"
      @click="$store.commit('toggleMapStatus')"
      class="px-2 ml-2 h-14 w-14 rounded-lg justify-center flex items-center absolute left-3 top-3 z-50 shadow-xl map"
      :class="$store.state.mapView == true ? 'bg-black ' : 'bg-white'"
    >
      <img
        class="w-6 h-6"
        :src="
          $store.state.mapView == false
            ? require('@/assets/marker/mapstatus.svg')
            : require('@/assets/marker/mapstatus_white.svg')
        "
      />
    </button>

    <!-- :route="route[activeRoute]" -->

    <fullWiewPanel
      v-if="showFullDetail"
      @hidePanel="showFullDetail = false"
      :endPosition="endPosition"
      :startPosition="startPosition"
      :polyLine="polyLine"
      :forbidenBounds="forbidenBounds"
      :avoidPointsList="avoidPointsList"
      :waypoints="waypoints"
      :tolls="tolls"
      :routes="route"
      :bridges="bridges"
      :isExplosiveDefault="explosiveState"
      @change="(r) => $emit('change', r)"
      @changeStartPosition="(val) => $emit('changeStartPosition', val)"
      @changeEndPosition="(val) => $emit('changeEndPosition', val)"
      @changeOutpoint="(r) => $emit('changeOutpoint', r)"
      @changeCustomer="(r) => $emit('changeCustomer', r)"
      :customerId="customer"
      :outpointId="outpoint"
      :custompoints="customPoints"
    />
    <GmapMap
      v-if="
        !load &&
        startPosition.lat &&
        startPosition.lng &&
        endPosition.lat &&
        endPosition.lng
      "
      @click="showFullDetail = true"
      :center="center"
      :zoom="20"
      :map-type-id="$store.state.mapView ? 'hybrid' : 'roadmap'"
      :options="{
        minZoom: 6,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
      style="width: 100%; border-radius: 20px"
      class="rounded-md"
      :style="'height:' + height"
    >
      <GmapMarker
        v-if="
          !load &&
          startPosition.lat &&
          startPosition.lng &&
          endPosition.lat &&
          endPosition.lng
        "
        v-for="m in markers"
        :key="m.id"
        :title="m.title"
        :label="{
          text: m.title, // + '-' + m.speedDirection
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        }"
        :position="m.position"
        :icon="m.icon"
      >
      </GmapMarker>

      <GmapMarker :position="startPosition" @click="center = startPosition" />

      <GmapMarker
        v-for="(item, index) in waypoints"
        v-show="item.no"
        :key="index"
        :position="item"
        :label="{
          text: item.no + '. Teslim Noktası',
          color: 'white',
          className:
            'absolute top-0.5 left-0  text-sm  bg-black rounded px-2 text-xs font-bold text-white',
          fontSize: '10px',
        }"
        :icon="{
          url: locationPinImages[(item.no - 1) % 5],
          scaledSize: { width: 24, height: 24 },
        }"
      />

      <GmapMarker
        v-for="(item, index) in bridges"
        :key="'bridge-' + index"
        :icon="require('@/assets/marker/bridge.png')"
        :position="{ lat: item.startLat, lng: item.startLng }"
        :label="{
          text: item.name,
          color: 'black',
          className:
            'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
          fontSize: '10px',
        }"
      />

      <GmapMarker
        v-for="(item, index) in customPoints"
        :key="'bridge-' + index"
        :icon="require('@/assets/marker/customspoints.png')"
        :position="{ lat: Number(item.latitude), lng: Number(item.longitude) }"
        :label="{
          text: item.name,
          color: 'black',
          className:
            'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
          fontSize: '10px',
        }"
      />

      <GmapPolyline
        v-bind:path.sync="polyLine"
        v-bind:options="{ strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>

      <div v-for="(item, index) in tolls" :key="'toll-' + index">
        <GmapMarker
          v-if="item.typeId === 1"
          :position="{ lat: item.startLat, lng: item.startLng }"
          :icon="require('@/assets/marker/hgs.png')"
          @click="center = { lat: item.startLat, lng: item.startLng }"
          :label="{
            text: `Giriş : ${item.name}`,
            color: 'black',
            className:
              'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
            fontSize: '10px',
          }"
        />

        <GmapMarker
          v-if="item.typeId === 1"
          :position="{ lat: item.endLat, lng: item.endLng }"
          :icon="require('@/assets/marker/hgs.png')"
          @click="center = { lat: item.endLat, lng: item.endLng }"
          :label="{
            text: `Çıkış : ${item.name}`,
            color: 'black',
            className:
              'absolute top-1 left-0 text-sm  bg-white  rounded px-2 text-xs font-bold',
            fontSize: '10px',
          }"
        />

        <GmapMarker
          v-if="item.typeName === 2"
          :position="{ lat: item.startLat, lng: item.startLng }"
          :icon="require('@/assets/marker/barrier.png')"
          @click="center = { lat: item.startLat, lng: item.startLng }"
          :label="{
            text: item.name,
            color: 'black',
            className:
              'absolute top-1 -right-1 text-sm   rounded bg-white px-2 text-xs font-bold',
            fontSize: '10px',
          }"
        />
      </div>

      <GmapMarker
        :position="endPosition"
        :icon="require('@/assets/marker/flag.png')"
        @click="center = endPosition"
      />
    </GmapMap>

    <button
      v-if="load == false && route.length > 1"
      type="button"
      @click="changeRoute()"
      class="text-sm bg-white absolute bottom-1 p-2 rounded text-black left-2"
    >
      <i class="fas fa-exchange-alt"></i>
      Alternatif Rota ({{ activeRoute + 1 + "/" + route.length }})
    </button>
  </div>
</template>
<script>
import axios from "axios";
import { tracking } from "@/networking/urlmanager";
import { routeEngine } from "@/networking/routeEngine";
import fullWiewPanel from "./fullView.vue";
import { decode } from "../../../utils/encoder";
import { managementCosting } from "../../../networking/urlmanager";
export default {
  name: "route-viewer",
  props: [
    "center",
    "height",
    "startPosition",
    "endPosition",
    "routeString",
    "editState",
    "explosiveState",
    "customer",
    "outpoint",
    "waypointsList",
    "vehicle",
  ],
  components: {
    fullWiewPanel,
  },
  data() {
    return {
      markers: [],
      // route: [],
      route: {},
      activeRoute: 0,
      waypoints: [],
      customPoints:[],
      locationPinImages: [
        require("@/assets/marker/location-pin.png"),
        require("@/assets/marker/location-pin-2.png"),
        require("@/assets/marker/location-pin-3.png"),
        require("@/assets/marker/location-pin-4.png"),
        require("@/assets/marker/location-pin-5.png"),
      ],
      bridges: [],
      tolls: [],
      polyLine: [],
      avoidPointsList: [],
      forbidenBounds: null,
      load: true,
      isToll: false,
      showFullDetail: false,
      fuelPrice: 0,
      alternative: 0,
    };
  },
  methods: {
    getAll(load = true) {
      this.markers = [];
      this.load = load;
      axios
        .get(tracking.getAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.load = false;
          if (Array.isArray(res.data.data)) {
            if (res.data.data.length > 0) {
              let markers = res.data.data.filter(
                (r) =>
                  r.vehicle?.toLowerCase() ==
                  this.vehicle?.toLowerCase().replace(/\s/g, "")
              );
              this.center = {
                lat: markers[0]?.latitude,
                lng: markers[0]?.longitude,
              };
              this.position = {
                lat: markers[0]?.latitude,
                lng: markers[0]?.longitude,
              };
              this.markers = markers.map((el) => {
                return {
                  id: el.id,
                  title: el.vehicle,
                  region: el.region,
                  position: {
                    lat: parseFloat(el?.latitude),
                    lng: parseFloat(el?.longitude),
                  },
                  adress: el.address,
                  vehicle: el.vehicle,
                  dorse: el.dorse,
                  planning: el.planning,
                  state: el.state,
                  target:
                    el.planning.state < 4
                      ? {
                          adress: el.outpointAdress,
                          lat: Number(el.outpointLat),
                          lng: Number(el.outpointLng),
                        }
                      : {
                          adress: el.targetAdress,
                          lat: Number(el.targettLat),
                          lng: Number(el.targettLng),
                        },
                  isBulkOperation: el.isBulkOperation,
                  icon: this.positionMarker(el.speed, el.speedDirection),
                };
              });
            }
          }
        })
        .catch((err) => {
          this.load = false;
        });
    },
    getRoute(loadState = true) {
      this.activeRoute = 0;
      this.load = loadState;
      axios
        .post(
          routeEngine.generate,
          {
            from: {
              lat: this.startPosition.lat,
              lng: this.startPosition.lng,
            },
            to: {
              lat: this.endPosition.lat,
              lng: this.endPosition.lng,
            },
            waypoints: JSON.stringify(Array.isArray(this.waypoints) ? this.waypoints : []),
            isToll: 1,
            vehicleTypeId: 5, // tır
            routeType: "fast",
            shippedHazardousGoods: this.explosiveState,
            isShip: 1,
            isAsphalt: 1,
            fuelRatio: 35,
            isPolyline: 1,
            AlternativeRouteCount:0

            // alternative: this.alternative,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.routeEngineToken,
            },
          }
        )
        .then((res) => {
          this.route = res.data.data;
          this.routeGenerate();
        })
        .catch((err) => {
          console.log("index getRoute err: ", err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async getManagmentConst(km) {
      const res = await axios.get(managementCosting.getAll, {
        headers: {
          Authorization: "Bareer " + this.$store.state.userData.token,
        },
      });
      const kilometerForFuel = 0.34;
      const fuelPrice = Number(res.data.data[20].price.replace(",", "."));
      this.fuelPrice = km * kilometerForFuel * fuelPrice;
    },
    routeGenerate() {
      // const data = this.route;
      const data = this.route[this.activeRoute];


      this.getManagmentConst(data.kilometer);


      this.polyLine = decode(data.routePolyline).polyline;

      this.$emit("*", this.polyLine);


      this.customPoints=Array.isArray(data?.customPoints) ? data?.customPoints : [];


      const parsedCostDetail = data.costDetail.map((item) => {
        return {
          ...item,
          startLat: Number(item.startLat),
          startLng: Number(item.startLng),
          endLat: Number(item.endLat),
          endLng: Number(item.endLng),
        };
      });

      const tollsArr = parsedCostDetail.filter(
        (item) => item.typeName !== "Bridge"
      );
      const bridgeArr = parsedCostDetail.filter(
        (item) => item.typeName === "Bridge"
      );

      this.tolls = tollsArr;
      this.bridges = bridgeArr;

      this.$emit("change", {
        cost: {
          fuel: this.fuelPrice,
          cash: data.totalCost,
        },
        polyLine: JSON.stringify({
          startPosition: this.startPosition,
          waypoints: this.waypoints,
          endPosition: this.endPosition,
        }),
        totalMinutes: data.totalMinutes,
        navigationLink: data.navigationLink,
        totalKilometer: data.kilometer,
        tolls: data.tolls,
        bridges: data.bridges,
      });
    },
    removePlace(index) {
      this.waypoints.splice(index, 1);
      this.getRoute(false);
    },
    getPlace(val) {
      this.waypoints.push({
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      });
      this.getRoute(false);
    },
    setPlace(val, index) {
      this.waypoints[index] = {
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      };
      this.getRoute(false);
    },
    changeRoute(){
      if((this.route.length-1)!=this.activeRoute){
        this.activeRoute++;
      }else this.activeRoute=0;
    }
  },
  created() {
    if (Array.isArray(this.waypointsList)) {
      this.waypoints = this.waypointsList.map((item) => {
        return {
          no: item.no,
          adress: item.position.adress,
          lat: item.position.lat,
          lng: item.position.lng,
        };
      });
    }
    this.getRoute();
    this.getAll(true);
  },
  watch: {
    waypointsList(val) {
      if (Array.isArray(val)) {
        this.waypoints = val.map((item) => {
          return {
            no: item.no,
            isWaypoint: item.isWaypoint,
            adress: item.position.adress,
            lat: item.position.lat,
            lng: item.position.lng,
          };
        });
        this.getRoute();
      }
    },
    load(val) {
      this.$emit("toggleRouteLoad", val);
    },
    activeRoute(){
      this.routeGenerate()
    }
  },
};
</script>

<style></style>
