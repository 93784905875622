<template>
  <modal
    name="edit-customer-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <logModule :moduleId="4" :rowId="row.id" />
    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex items-center justify-center"
    >
      <h4 class="inline-block w-10/12">
        {{ $t("operations.customers.modal.editCustomer") }}
      </h4>
      <div class="mt-4 w-2/12 ml-auto flex items-center">
        <div class="w-6/12">
          <logBtn />
        </div>
        <div class="w-6/12">
          <button class="" @click="$modal.hide('edit-customer-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Header -->

    <!-- Body -->

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          :title="$t('operations.customers.modal.customerName')"
          v-model="name"
          maxlength="500"
          minlength="2"
          required
          type="Text"
        />
      </div>
      <div class="w-full mt-4 flex justify-center items-center">
        <diffInput
          class="mr-4"
          title="Öncelik Puanı (1-10 arasında)"
          v-model="priorty"
          :min="1"
          :max="10"
          required
          type="number"
          :step="1"
        />
        <diffInput
          class=""
          title="Müşteri Numarası"
          v-model="phone"
          :min="1"
          :max="14"
          required
          type="text"
          :step="1"
        />
      </div>
      <div class="mt-4">
        <diffInput
          class=""
          title="Email"
          v-model="email"
          :min="1"
          :max="100"
          required
          type="email"
          :step="1"
        />
      </div>
      <div class="mt-4">
        <diffInput
          title="Hassasiyet (metre)"
          v-model="sensibility"
          min="10"
          max="20000"
          required
          type="number"
          :step="1"
        />
      </div>
      <div class="mt-4">
        <diffInput
          title="Boşaltma Noktası Sayısı"
          v-model="unloadingPointCount"
          min="1"
          max="100"
          required
          type="number"
          :step="1"
        />
      </div>

      <div class="mt-4">
        <diffInput
          title="Zorunlu Yıkatma Durumu"
          v-model="requiredWashingStatus"
          required
          type="select"
          :optList="[
            {
              id: '2',
              name: 'Hayır',
            },
            {
              id: '1',
              name: 'Evet',
            },
          ]"
        />
      </div>

      <div class="mt-4">
        <GmapItem
          placeHolder="Konum | Adres"
          height="200px"
          @change="(val) => (position = val)"
          :draggable="true"
          :lat="position.lat"
          :lng="position.lng"
          :defaultAdress="position.adress"
        />
      </div>

      <div class="mt-4">
        <workDays v-model="workDayList" :isEdit="true" />
      </div>
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import GmapItem from "@/components/general/gmapInput.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";

//Local Components
import workDays from "./workDays.vue";

// networking
import axios from "axios";
import { customer } from "@/networking/urlmanager";
export default {
  name: "edit-customer-modal",
  props: ["row"],
  components: {
    diffInput,
    asyncBtn,
    logModule,
    GmapItem,
    logBtn,
    workDays,
  },
  data() {
    return {
      name: "",
      priorty: 1,
      phone: 0,
      sensibility: 0,
      unloadingPointCount: 0,
      requiredWashingStatus: 2,
      position: {
        lat: 0,
        lng: 0,
        adress: "",
      },
      load: false,
      workDayList: [],
      email: "",
    };
  },
  methods: {
    getDetail() {
      this.email = this.row.email;
      this.phone = this.row.phone;
      this.name = this.row.name;
      this.priorty = this.row.priority;
      this.sensibility = this.row.sensibility;
      this.requiredWashingStatus = this.row.requiredWashingStatus;
      this.unloadingPointCount = this.row.unloadingPointCount;
      this.position = {
        adress: this.row?.address,
        lat: Number(this.row?.latitude),
        lng: Number(this.row?.longitude),
      };
      this.workDayList = this.row.shiftHours.map((item) => ({
        ...item,
        status: 1,
        day:
          item.dayId === 1
            ? "Pazartesi"
            : item.dayId === 2
            ? "Salı"
            : item.dayId === 3
            ? "Çarşamba"
            : item.dayId === 4
            ? "Perşembe"
            : item.dayId === 5
            ? "Cuma"
            : item.dayId === 6
            ? "Cumartesi"
            : item.dayId === 7
            ? "Pazar"
            : "",
      }));
    },
    save() {
      if (this.isEmptyText(this.name)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen müşteri ismini eksiksiz olarak doldurun!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      this.load = true;
      axios
        .post(
          customer.edit,
          {
            rowId: this.row.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            priorty: this.priorty,
            sensibility: this.sensibility,
            address: this.position.adress,
            latitude: this.position.lat,
            longitude: this.position.lng,
            requiredWashingStatus: this.requiredWashingStatus,
            unloadingPointCount: this.unloadingPointCount,
            shiftHours: this.workDayList,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.name = "";
            this.priorty = "1";
            this.sensibility = "";
            this.unloadingPointCount = "";
            this.requiredWashingStatus = "2";
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-customer-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },
};
</script>
