<template>
  <modal
    name="add-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '90%'"
    :scrollable="true"
    @opened="openModal()"
    :clickToClose="false"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 "
    >
      <h4 class="inline-block w-11/12">Ekle</h4>

      <button class="p-2 w-1/12" @click="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div>
      <div class="p-5 h-[500px] overflow-y-auto xl:pb-1 pb-10">
        <div class="space-y-4">
          <div class="grid grid-cols-2 gap-5">
            <diffInput
              type="text"
              max="255"
              min="1"
              v-model="name"
              :required="true"
              title="Araç Plakası"
              class="text-base"
            />
            <diffInput
              type="text"
              max="255"
              min="1"
              v-model="name"
              :required="true"
              title="Sürücü İsmi"
              class="text-base"
            />
          </div>
          <div class="w-full grid grid-cols-2 gap-5">
            <diffInput
              v-model="startedDate"
              :max="complatedDate"
              type="datetime-local"
              :required="true"
              placeholder="Duruş Başlama Tarih ve Saati"
              :min="minDate"
              title="Duruş Başlama Tarih ve Saati"
            />

            <!-- :min="getNowDate" -->
            <diffInput
              v-model="complatedDate"
              :min="startedDate ? startedDate : minDate"
              :max="maxDate"
              type="datetime-local"
              :required="true"
              :oldDate="true"
              placeholder="Duruş Bitiş Tarih ve Saati"
              title="Duruş Bitiş Tarih ve Saati"
            />
          </div>
    
       
          <div class="w-full mt-4 grid grid-cols-1 pb-6">
            <diffInput
              type="textarea"
              v-model="explanation"
              title="Duruş Sebebi"
              maxlength="2800"
              :required="false"
            />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
//global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import diffInput from "@/components/general/diffInput.vue";
export default {
  name: "add-plan-modal",

  components: {
    asyncBtn,
    diffInput,
  },

  data() {
    return {};
  },

  methods: {
    close() {
      this.$modal.hide("add-plan-modal");
      this.$emit("refresh", true);
    },

  },
  watch: {},
};
</script>
