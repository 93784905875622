var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"vehicle-modal","height":_vm.isMobile ? '100%' : 'auto',"width":_vm.isMobile ? '100%' : '80%',"scrollable":true}},[_c('div',{staticClass:"pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"},[_c('h4',{staticClass:"text-xl font-semibold"},[_vm._v("ÖNERİLEN ARAÇ DETAYI")]),_c('button',{staticClass:"px-3 text-lg",attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.hide('vehicle-modal')}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('div',{staticClass:"pb-4 px-4 pt-2 xl:flex items-start justify-between 2xl:mb-20 mb-8",style:({ height: '60vh' })},[_c('div',{staticClass:"w-full xl:w-1/2 mb-4 xl:mb-0"},[(_vm.detail)?_c('mapItem',{attrs:{"item":_vm.detail,"height":"35vh"}}):_vm._e(),_c('div',{staticClass:"mt-9"},[_c('label',{staticClass:"block font-bold text-md mb-2"},[_vm._v("Muayene Bitiş Tarihleri")]),_c('RecommendationDetail',{attrs:{"label":"Araç Muayene Tarihi","value":_vm.formattedEndDate(
              _vm.detail?.recomendation?.vehicleInspectionEndDate
                ? _vm.detail?.recomendation?.vehicleInspectionEndDate
                : _vm.detail?.vehicleInspectionEndDate
            ),"date":true}}),_c('RecommendationDetail',{attrs:{"label":"Dorse Muayene Tarihi","date":true,"value":_vm.formattedEndDate(
              _vm.detail?.recomendation?.inspectionEndDate
                ? _vm.detail?.recomendation?.inspectionEndDate
                : _vm.detail?.inspectionEndDate
            )}}),_c('RecommendationDetail',{attrs:{"label":"Araç ADR Muayene Tarihi","value":_vm.formattedEndDate(
              _vm.detail?.recomendation?.vehicleAdrEndDate
                ? _vm.detail?.recomendation?.vehicleAdrEndDate
                : _vm.detail?.vehicleAdrEndDate
            ),"date":true}}),_c('RecommendationDetail',{attrs:{"label":"Dorse ADR Muayene Tarihi","value":_vm.formattedEndDate(
              _vm.detail?.recomendation?.dorseAdrEndDate
                ? _vm.detail?.recomendation?.dorseAdrEndDate
                : _vm.detail?.dorseAdrEndDate
            ),"date":true,"lastItem":true}})],1)],1),_c('div',{staticClass:"w-full xl:w-1/2 pb-4 px-4 space-y-2"},[_c('label',{staticClass:"block font-bold text-lg mb-2"},[_vm._v("ÖNERİ NEDENLERİ")]),_c('RecommendationDetail',{attrs:{"label":"Araç Sahipliği","value":_vm.vehicle()}}),_c('RecommendationDetail',{attrs:{"label":"Yükleme Noktasına Yakınlık","value":`${_vm.detail?.recomendation?.distancePriority} KM`}}),_c('RecommendationDetail',{attrs:{"label":"Müşteri Önceliği","value":_vm.detail?.recomendation?.customerPriority}}),_c('RecommendationDetail',{attrs:{"label":"Araç Kapasitesi","value":_vm._f("formatCapacity")(_vm.detail?.recomendation?.capacity === '0.00'
            ? 26
            : _vm.detail?.recomendation?.capacity)}}),_c('RecommendationDetail',{attrs:{"label":"Yük Miktarı","value":_vm._f("formatCapacity")(_vm.detail?.tonnage)}}),_c('RecommendationDetail',{attrs:{"label":"Araç Muayene Bitiş Tarihi","iconClass":_vm.detail?.recomendation?.isInspectionAvailable
            ? 'fas fa-check text-green-500'
            : 'fas fa-times text-red-500'}}),_c('RecommendationDetail',{attrs:{"label":"Araç Müsaitliği","iconClass":_vm.detail?.recomendation?.isVehicleAvailable
            ? 'fas fa-check text-green-500'
            : 'fas fa-times text-red-500'}}),_c('RecommendationDetail',{attrs:{"label":"ADR Gereksinimini Karşılıyor mu?","iconClass":_vm.detail?.recomendation?.isADRCompatible
            ? 'fas fa-check text-green-500'
            : 'fas fa-times text-red-500'}}),(_vm.detail.orderAmount <= 30)?_c('RecommendationDetail',{attrs:{"label":"Araç Tonajı Uygun Mu?","iconClass":_vm.detail?.recomendation?.isTonnageSuitable
            ? 'fas fa-check text-green-500'
            : 'fas fa-times text-red-500',"lastItem":true}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }