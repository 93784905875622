<template>
  <modal
    name="edit-reserved-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.planning.modal.editTitle") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('edit-reserved-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <!-- Sipariş Dönüştürme Seçeneği -->

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            v-model="orderNo"
            title="Sipariş Numarası"
            :disabled="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            v-model="orderName"
            :disabled="true"
            title="Sipariş Adı"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-6/12 mt-4">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="orderDate"
            title="Sipariş Tarihi"
          />
        </div>
        <div class="w-full md:w-6/12 pl-2 mt-4">
          <diffInput
            type="select"
            :optList="[
            {
              id:1,
              name:'Tır'
            },
            {
              id:2,
              name:'Kamyon'
            }
            ]"
            minlength="1"
            :required="true"
            v-model="vehicleType"
            title="Araç Türü"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            :required="true"
            v-model="customer"
            title="Müşteri"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :title="$t('operations.planning.modal.billedCompany')"
            v-model="invoicedCompany"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            title="Gönderici"
            v-model="receivedName"
            :readonly="true"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            title="Alıcı"
            v-model="deliveredName"
            :readonly="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <gmapInputVue
            @change="(r) => changeFillingPoint(r)"
            :defaultAdress="outpointAdress"
            :lat="outpointLat"
            :lng="outpointLng"
            placeHolder="Gönderici Adresi"
            height="150px"
            :hideMap="true"
            :readonly="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <gmapInputVue
            @change="(r) => changeDeliveryPoint(r)"
            :defaultAdress="targetAdress"
            :lat="targetPointLat"
            :lng="targetPointLng"
            placeHolder="Alıcı Adresi"
            height="150px"
            :hideMap="true"
            :readonly="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            v-model="productType"
            :required="true"
            title="Mal Sınıfı"
          />
        </div>
        <div class="w-full mt-4 md:w-4/12 pl-2">
          <diffInput
            type="number"
            :required="true"
            v-model="tonnage"
            title="Tonaj"
          />
        </div>
        <div class="w-full mt-4 md:w-2/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            title="Birim"
            v-model="unitType"
          />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="w-6/12 mt-4">
          <diffInput
            type="date"
            v-model="deliveryDate"
            :required="true"
            :title="$t('operations.planning.modal.deliveryTime')"
          />
        </div>

        <div class="w-6/12 pl-2 mt-4">
          <diffInput
            type="time"
            v-model="deliveryTime"
            :required="true"
            title="Teslimat Saati"
          />
        </div>
      </div>

      <div class="w-full mt-4 flex items-center justify-between text-right">
        <asyncBtn
          icon="fas fa-recycle"
          text="Siparişe Dönüştür"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          @click="handleSubmit(1)"
          class="w-full md:w-56 mr-2"
        />
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          @click="handleSubmit(0)"
          class="w-full md:w-56 bg-blue-900"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import routeViewiver from "@/components/devItem/route-generator/index.vue";
import axios from "axios";
import { planning } from "@/networking/urlmanager";
import moment from "moment";
import { orders } from "../../../../networking/urlmanager";
export default {
  name: "edit-reserved-plan-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    gmapInputVue,
    routeViewiver,
  },
  data() {
    return {
      customer: "Mumbai Exports Pvt. Ltd.",
      customerId: 0,
      deliveredName: "Anna",
      deliveryDate: "2024-12-20T20:00",
      deliveryTime: "20:00",
      invoicedCompany: "Mumbai Exports Pvt. Ltd.",
      isApproval: 0,
      orderDate: "2024-11-25 00:00",
      orderName: "Sipariş 6534",
      orderNo: "17307594",
      outpointAdress: "123 Business Park, Hindistan",
      outpointLat: null,
      outpointLng: null,
      productType: "Kamyon",
      receivedName: "Hasan Akil",
      source: 1,
      targetAdress: "XYZ Ticaret A.Ş., İstanbul",
      targetPointLat: null,
      targetPointLng: null,
      tonnage: 26,
      unitType: "TON",
      vehicleType: "1",
      unplannedOrder: 1,
      weight: 18000,
      load: false,
      convertToOrder: false,
    };
  },
  methods: {
    getDetail() {
      console.log(this.detail)
      this.customer = this.detail.customer;
      this.deliveredName = this.detail.deliveredName;
      this.receivedName = this.detail.receivedName;
      this.orderDate = moment(this.detail.orderDate).format("YYYY-MM-DD");
      this.deliveryDate = moment(this.detail.deliveryDate).format("YYYY-MM-DD");
      this.deliveryTime = moment(this.detail.deliveryDate).format("HH:mm");
      this.productType = this.detail.product;
      this.outpointAdress = this.detail.outpointAdress;
      this.targetAdress = this.detail.targetAdress;
      this.tonnage = this.detail.tonnage;
      this.weight=this.detail.weight

      // Implementation remains the same
    },
    async handleSubmit(approvalState=0) {
      this.load=true;
        // Siparişe dönüştürme onayı

        axios.post(orders.editReserve, {
          rowId:this.detail.rowId,
          orderNo:this.detail.orderNo,
          orderName:this.detail.orderName,
          orderDate:this.orderDate,
          customer:this.customer,
          invoicedCompany:this.invoicedCompany,
          receivedName:this.receivedName,
          deliveredName:this.deliveredName,
          productType:this.productType,
          amount:Number(this.tonnage)>1000 ? Number(this.tonnage)/1000 : this.tonnage,
          unitType:this.unitType,
          fillingDate:this.orderDate,
          deliveryDate:this.deliveryDate,
          vehicle:this.vehicleType,
          outpointAdress:this.outpointAdress,
          outpointLat:this.outpointLat ?? this.detail.outpointLat,
          outpointLng:this.outpointLng ?? this.detail.outpointLng,
          targetpointAdress:this.targetAdress,
          targetpointLat:this.targetPointLat ?? this.detail.targetPointLat,
          targetpointLng:this.targetPointLng ?? this.detail.targetPointLng,
          weight:this.weight,
          typeId:1,
          isApproval:approvalState
        }, {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }).then(() => {
          this.$swal({
            title: "İşlem başarılı",
            text: "işlem başarılı bir şekilde tamamlandı.",
            icon: "success"
          })
          
          this.$emit('refresh',true)
          this.load=false;
          this.$modal.hide('edit-reserved-modal')
        }).catch(err=>{
          this.load=false;
          this.$swal({
            title: "UYARI!",
            text: err?.response?.data?.message ? err?.response?.data?.message : err, 
            icon: "warning",

          })
        });
    },
    changeFillingPoint(item) {
      // this.changeLocationState = false;
      this.outpointAdress = item.adress;
      this.outpointLat = item.lat;
      this.outpointLng = item.lng;
      
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      // this.deliveryPoint = item;
      this.targetAdress = item.adress;
      this.targetPointLat = item.lat;
      this.targetPointLng = item.lng;

      // if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
      //   setTimeout(() => {
      //     this.changeLocationState = true;
      //     this.$refs.routerViev.getRoute();
      //   }, 200);
      // }
    },


    }
};
</script>