<template>
  <modal
    name="edit-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '90%'"
    :scrollable="true"
    :clickToClose="false"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">Düzenle</h4>

      <button class="p-2 w-1/12" @click="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="save()">
      <div class="p-5 h-[500px] overflow-y-auto xl:pb-1 pb-10">
        <div class="space-y-4">
          <div class="grid grid-cols-2 gap-5">
            <diffInput
              type="text"
              max="255"
              min="1"
              :value="plaque"
              :required="true"
              title="Araç Plakası"
              class="text-base"
              :disabled="true"
            />
            <diffInput
              type="text"
              max="255"
              min="1"
              :value="driverName"
              :required="true"
              title="Sürücü İsmi"
              class="text-base"
              :disabled="true"
            />
          </div>
          <div class="w-full grid grid-cols-2 gap-5">
            <diffInput
              v-model="startedDate"
              :max="complatedDate"
              type="datetime-local"
              :required="true"
              placeholder="Duruş Başlama Tarih ve Saati"
              :min="minDate"
              title="Duruş Başlama Tarih ve Saati"
            />

            <!-- :min="getNowDate" -->
            <diffInput
              v-model="complatedDate"
              :min="startedDate ? startedDate : minDate"
              type="datetime-local"
              :required="true"
              :oldDate="true"
              placeholder="Duruş Bitiş Tarih ve Saati"
              title="Duruş Bitiş Tarih ve Saati"
            />
          </div>

          <div class="w-full mt-4 pb-4">
            <diffInput
              type="select"
              v-model="typeId"
              title="Duruş Sebebi"
              maxlength="2800"
              :required="false"
              :optList="[
                {
                  id: 1,
                  name: 'Bakımda',
                },
                {
                  id: 2,
                  name: 'Teknik Arıza',
                },
                {
                  id: 3,
                  name: 'Trafikte',
                },
              ]"
            />
          </div>
          <div class="w-full text-right py-3">
            <asyncBtn
              icon="fas fa-check"
              text="Kaydet"
              loadTitle="Yükleniyor"
              :loadState="load"
              class="w-full md:w-40 bg-green-700"
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
//global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
// import { reports } from "../../../../../networking/urlmanager";
export default {
  name: "add-plan-modal",
  props: ["plaque", "driverName", "driverTC"],
  components: {
    asyncBtn,
    diffInput,
  },

  data() {
    return {
      load: false,
      startedDate: "",
      complatedDate: "",
      typeId:1,
      minDate: moment().format("YYYY-MM-DD"),
    };
  },

  methods: {
    async save() {
      
      // try {
      //   this.load = true;
      //   await axios.post(reports.addVehicleMaintenance,{
      //     vehicle:this.plaque, 
      //     driverName:this.driverName,
      //     driverTc:this.driverTC, 
      //     startDate:this.startedDate, 
      //     endDate:this.complatedDate,
      //     reasonId:this.typeId
      //   },{
      //     headers:{
      //       Authorization: `Bearer ${this.$store.state.userData.token}`,  
      //     }
      //   })

      //   this.load=false;
      //   this.close();
      // } catch (error) {
      //   console.log(error);
      //   alert("İŞLEM BAŞARISIZ OLDU!");
      // }
    },
    close() {
      this.$modal.hide("edit-plan-modal");
      this.$emit("refresh", true);
    },
  },
  watch: {},
};
</script>
