<template>
  <modal
    name="add-bans-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <!-- Header -->
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        YENİ YASAKLAMA
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-bans-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Header -->

    <!-- Body -->
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          title="Grup Adı"
          v-model="name"
          type="Text"
        />
      </div>
      <div class="w-full mt-4">
        <CountrySelect v-model="country" :multiple="true" />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('operations.products.modal.explanation')"
          v-model="explanation"
          type="textarea"
        />
      </div>
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>

    <!-- Body -->
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

//networking
import axios from "axios";
import { banned } from "@/networking/urlmanager";

// local Components
import CountrySelect from "./selectors/country.select.vue";

export default {
  name: "add-product-modal",
  props: ["defaultName"],
  components: {
    diffInput,
    asyncBtn,
    CountrySelect
    // washPropertiesSelect,
  },
  data() {
    return {
      load: false,
      name: "",
      country:[],
      explanation: "",
    };
  },
  methods: {
    show() {
      this.name = "";
      this.country = [];
      this.$modal.show("add-bans-modal");
    },
    hide() {
      this.$modal.hide("add-bans-modal");
    },
    save() {
      if (this.isEmptyText(this.name)) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen ürün ismini eksiksiz olarak doldurun!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      this.load = true;
      axios
        .post(
          banned.add,
          {
            name: this.name,
            country:this.country,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.name = "";
            this.country=[];

            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
  },
  created() {
    this.name = this.defaultName;
  },
  watch: {
    defaultName(val) {
      this.name = val;
    },
    typeId(val) {
      if (val != 4) this.sdsId = "0";
    },
  },
};
</script>
