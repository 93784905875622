import baseURL from "./api";

export const vehicleStatus = {
  edit: baseURL + "/human/resource/operation/arrived/borderGate",
  completedEdit: baseURL + "/human/resource/operation/complated",
};
export const userEditVehicle = {
  edit: baseURL + "/operation/bulk/planning/edit/vehicle",
};
export const bulkShipPlanning = {
  //ship
  getAll: baseURL + "/operation/shipEvacuation/ship/get/all",
  delete: baseURL + "/operation/shipEvacuation/ship/delete",
  getActionController:
    baseURL + "/operation/shipEvacuation/ship/action/controller",

  //storage
  process: baseURL + "/operation/shipEvacuation/ship/evacuationProcess",

  //order
  getReportAll: baseURL + "/operation/shipEvacuation/order/get/reports/all",
  orderdersgetAll: baseURL + "/operation/shipEvacuation/order/get/all",
  orderdersDelete: baseURL + "/operation/shipEvacuation/order/delete",
  orderStatus: baseURL + "/operation/shipEvacuation/order/status",
  setState: baseURL + "/operation/shipEvacuation/order/set/state",
  startShip: baseURL + "/operation/shipEvacuation/ship/start",

  //vehicleAdd
  vehiclesAll: baseURL + "/operation/shipEvacuation/vehicle/get/all",
  vehicleDelete: baseURL + "/operation/shipEvacuation/vehicle/delete",
  vehicleAdd: baseURL + "/operation/shipEvacuation/vehicle/add/custom",
  vehicleSet: baseURL + "/operation/shipEvacuation/vehicle/set/vehicle",

  //shipAdd
  shipAdd: baseURL + "/operation/shipEvacuation/ship/add",
  orderAdd: baseURL + "/operation/shipEvacuation/order/add",
  vehicleBulkAdd: baseURL + "/operation/shipEvacuation/vehicle/add/bulk",
  getTonnageContoller:
    baseURL + "/operation/shipEvacuation/order/tonnage/controller",
};

export const authorityModule = {
  getAll: baseURL + "/users/get/auth",
  add: baseURL + "/users/add/auth",
  delete: baseURL + "/users/delete/auth",
};
//logModule
export const getLogModule = {
  getAll: baseURL + "/get/log/module",
};

//calculator
export const calculate = {
  add: baseURL + "/calculator/add",
  getAll: baseURL + "/calculator/get/all",
  delete: baseURL + "/calculator/delete",
  edit: baseURL + "/calculator/edit",
};

export const general = {
  getCityAll: baseURL + "/general/get/city/all",
  getDistrictAll: baseURL + "/general/get/district/all",
  haveTypeAll: baseURL + "/general/get/havetype/all",
  getFileUrl: baseURL + "/general/get/file/url",
  getFileTypeAll: baseURL + "/general/get/file/types",
  getPriceTypes: baseURL + "/general//get/price/types",
  exchangePrice: baseURL + "/general/get/excahnge/now",
  getCurrencires: baseURL + "/general/get/tcmb/currency",
};

export const bulkPlanning = {
  getAll: baseURL + "/operation/bulk/planning/get/all",
  getNotPaginationAll:
    baseURL + "/operation/bulk/planning/get/not/pagination/all",
  getActionController: baseURL + "/operation/bulk/planning/action/controller",
  getTonnageContoller: baseURL + "/operation/bulk/planning/tonnage/controller",

  saveAction: baseURL + "/operation/bulk/planning/action/save",
  actionLogs: baseURL + "/operation/bulk/planning/get/action/logs",
  getRecomendedController:
    baseURL + "/operation/bulk/planning/get/recomended/controller",
  getSingle: baseURL + "/operation/bulk/planning/get/single",
  add: baseURL + "/operation/bulk/planning/add",
  edit: baseURL + "/operation/bulk/planning/edit",

  delete: baseURL + "/operation/bulk/planning/delete",
  setState: baseURL + "/operation/bulk/planning/set/state",
  vehiclesAll: baseURL + "/operation/bulk/planning/get/vehicles/all",
  vehicleAdd: baseURL + "/operation/bulk/planning/add/vehicle",
  vehicleDelete: baseURL + "/operation/bulk/planning/delete/vehicle",
  vehicleSet: baseURL + "/operation/bulk/planning/set/vehicle",
  completePlanning: baseURL + "/operation/bulk/planning/complete/planning",
  // reports
  getReportAll: baseURL + "/operation/bulk/planning/get/reports/all",
  lastTenTonnageController:
    baseURL + "/operation/bulk/planning/lastTenTonnage/controller",
  getSubOrders: baseURL + "/operation/bulk/planning/get/sub/orders",
};

export const washPoint = {
  getAll: baseURL + "/washing/points/get/all",
  add: baseURL + "/washing/points/add",
  edit: baseURL + "/washing/points/edit",
  delete: baseURL + "/washing/points/delete",
  properties: baseURL + "/washing/points/get/property",
  getWashingLocations: baseURL + "/washing/points/get/not/pagination/all",
};

export const branches = {
  getAll: baseURL + "/branches/get/all",
  add: baseURL + "/branches/add",
  edit: baseURL + "/branches/edit",
  delete: baseURL + "/branches/delete",
};

export const restStops = {
  getAll: baseURL + "/resting/places/get/all",
  add: baseURL + "/resting/places/add",
  edit: baseURL + "/resting/places/edit",
  delete: baseURL + "/resting/places/delete",
};

export const fleetManagement = {
  getAll: baseURL + "/fleet/configuration/get/all",
  edit: baseURL + "/fleet/configuration/edit",
};

export const sos = {
  getAll: baseURL + "/human/resource/sos/get/controller",
  approvad: baseURL + "/human/resource/sos/complated",
};

export const tracking = {
  getAll: baseURL + "/tracking/get/all",
  getVectors: baseURL + "/tracking/get/vector/all",
  getFleetAll: baseURL + "/tracking/get/fleet/all",
  getGroupAll: baseURL + "/tracking/get/group/all",
  getLastTransition: baseURL + "/tracking/get/last/transition",
  getCurrentTransition: baseURL + "/tracking/get/active/transition",
  updateDeviation: baseURL + "/tracking/route/deviation/add/action",
};

export const user = {
  signIn: baseURL + "/users/sign/in",
  signControl: baseURL + "/users/sign/control",
  fargotPasswordCreate: baseURL + "/users/forgot/password/create",
  fargotPasswordSet: baseURL + "/users/forgot/password/set",
  setProfile: baseURL + "/users/set/profile",
  setPassword: baseURL + "/users/set/password",
  setLanguage: baseURL + "/users/set/language",
  setProfileImage: baseURL + "/users/set/profile/image",
  getProfileImage: baseURL + "/users/get/profile/image",
  setMode: baseURL + "/users/set/mode",
  complateFirstAuth: baseURL + "/users/complate/first/auth",
  signOut: baseURL + "/users/sign/out",

  feedback: {
    getAll: baseURL + "/users/get/feedback",
    add: baseURL + "/users/add/feedback",
  },
};

export const managementUser = {
  getAll: baseURL + "/management/user/get/all",
  getFilterAll: baseURL + "/management/user/get/filter/all",
  getAllNotPagination: baseURL + "/management/user/get/all/not/pagination",
  getTypeAll: baseURL + "/management/user/get/type/all",

  add: baseURL + "/management/user/add",
  edit: baseURL + "/management/user/edit",
  passive: baseURL + "/management/user/passive",
  active: baseURL + "/management/user/active",
  changeSafeState: baseURL + "/management/user/change/safe/state",

  getPermissions: baseURL + "/management/user/get/permission",
  changePermission: baseURL + "/management/user/change/permission",
};

export const reports = {
  getOptimumRoutes: baseURL + "/reporting/optimum/routes",
  getOptimumRoutes: baseURL + "/reporting/optimum/routes",

  getVehicleIdleTimes: baseURL + "/reporting/vehicle/empty",
  getVehicleIdleTimeDetail: baseURL + "/reporting/vehicle/empty/detail",

  getRouteDeviationReport: baseURL + "/tracking/route/deviation/get/all",
  getRouteDeviationAll:
    baseURL + "/tracking/route/deviation/get/all/not/pagination",

  getFuelAnomalies: baseURL + "/tracking/fuel/anomaly/get/all",
  getFuelAnomaliesAll:
    baseURL + "/tracking/fuel/anomaly/get/all/not/pagination",

  getServiceRequestReports: baseURL + "/reporting/get/serviceRequests",
};

export const routeZone = {
  getAll: baseURL + "/tracking/location/polygons/get/all",
  getAllData: baseURL + "/tracking/location/polygons/get/all/without/page",
  edit: baseURL + "/tracking/location/polygons/edit",
};

export const serviceRequests = {
  getserviceRequests: baseURL + "/human/resource/personnel/getServiceRequests",
  updateserviceRequest:
    baseURL + "/human/resource/personnel/updateServiceRequests",
};

export const customerWaitingTime = {
  getAll: baseURL + "/reporting/customer/waiting/time",
};
export const driverHomeVisitReport = {
  getAll: baseURL + "/reporting/driver/home/visit",
};
export const driverTally = {
  getAll: baseURL + "/reporting/get/tally/records",
  getTallyDetail: baseURL + "/reporting/get/tally/records/detail/by/date",
};

export const managementCompany = {
  getDetail: baseURL + "/management/company/get/detail",
  setDetail: baseURL + "/management/company/set/detail",
};
export const managementCosting = {
  getAll: baseURL + "/management/costing/get/all",
  save: baseURL + "/management/costing/save",
};

export const personnelManagement = {
  getAll: baseURL + "/human/resource/personnel/get/all",
  getFilterAll: baseURL + "/human/resource/personnel/get/filter/all",
  getSingle: baseURL + "/human/resource/personnel/get/single",
  getTypeAll: baseURL + "/human/resource/personnel/get/type/all",
  getAllNotPagination:
    baseURL + "/human/resource/personnel/get/all/not/pagination",

  add: baseURL + "/human/resource/personnel/add",
  edit: baseURL + "/human/resource/personnel/edit",
  delete: baseURL + "/human/resource/personnel/delete",
  reactive: baseURL + "/human/resource/personnel/reactive",

  getGeneralTypeAll: baseURL + "/human/resource/personnel/get/general/type/all",
};

export const vehicle = {
  getAll: baseURL + "/inventory/vehicle/get/all",
  getFilterAll: baseURL + "/inventory/vehicle/get/filter/all",
  getSingle: baseURL + "/inventory/vehicle/get/single",

  getAllNotPagination: baseURL + "/inventory/vehicle/get/all/not/pagination",
  getTypeAllVehicle: baseURL + "/inventory/vehicle/get/type/all",
  getTypeAll: baseURL + "/inventory/vehicle/get/type/general/all",
  getStates: baseURL + "/inventory/vehicle/get/state/general/all",

  add: baseURL + "/inventory/vehicle/add",
  edit: baseURL + "/inventory/vehicle/edit",
  delete: baseURL + "/inventory/vehicle/delete",
  reactive: baseURL + "/inventory/vehicle/reactive",
};

export const product = {
  getAll: baseURL + "/operation/product/get/all",
  getFilterAll: baseURL + "/operation/product/get/filter/all",
  getSingle: baseURL + "/operation/product/get/single",
  getAllNotPagination: baseURL + "/operation/product/get/all/not/pagination",
  getWithFilterAll:
    baseURL + "/operation/product/get/product/and/connect/dorse/type",

  add: baseURL + "/operation/product/add",
  edit: baseURL + "/operation/product/edit",
  delete: baseURL + "/operation/product/delete",

  // general
  getSpecification: baseURL + "/operation/product/get/specification/all",
  getProductTypes: baseURL + "/operation/product/get/product-type/all",
  getSdsFiles: baseURL + "/operation/product/get/sds/all",
  getDorseTypes: baseURL + "/operation/product/get/general/dorse/type/all",

  properties: {
    add: baseURL + "/operation/product/properties/add",
    delete: baseURL + "/operation/product/properties/delete",
  },

  requiments: {
    add: baseURL + "/operation/product/requiments/add",
    delete: baseURL + "/operation/product/requiments/delete",
  },

  getTables: baseURL + "/operation/product/get/tables/general/all",
  getImmovables: baseURL + "/operation/product/get/immovables/all",
};

export const banned = {
  getAll: baseURL + "/operation/banned/get/all",
  getSingle: baseURL + "/operation/banned/get/single",
  getAllNotPagination: baseURL + "/operation/banned/get/all/not/pagination",

  add: baseURL + "/operation/banned/add",
  edit: baseURL + "/operation/banned/edit",
  delete: baseURL + "/operation/banned/delete",

  // general
  getCountry: baseURL + "/operation/banned/get/country/general/all",

  getTables: baseURL + "/operation/product/get/tables/general/all",
  getImmovables: baseURL + "/operation/product/get/immovables/all",
};
export const customer = {
  getAll: baseURL + "/operation/customer/get/all",
  getFilterAll: baseURL + "/operation/customer/get/filter/all",
  getSingle: baseURL + "/operation/customer/get/single",
  getAllNotPagination: baseURL + "/operation/customer/get/all/not/pagination",

  add: baseURL + "/operation/customer/add",
  edit: baseURL + "/operation/customer/edit",
  delete: baseURL + "/operation/customer/delete",

  requiments: {
    add: baseURL + "/operation/customer/requiments/add",
    delete: baseURL + "/operation/customer/requiments/delete",
  },

  outpoints: {
    getAll: baseURL + "/operation/customer/get/outpoint/all",
    add: baseURL + "/operation/customer/outpoint/add",
    edit: baseURL + "/operation/customer/outpoint/edit",
    delete: baseURL + "/operation/customer/outpoint/delete",
  },
};

export const orders = {
  getAll: baseURL + "/operation/order/get/all/orders",
  getPlanningAll: baseURL + "/operation/order/get/all",
  getVehicle: baseURL + "/tracking/get/vehicle/activity",
  getNotPaginationAll: baseURL + "/operation/order/get/not/pagination/all",
  getSingle: baseURL + "/operation/order/get/single",

  applyVehicle: baseURL + "/operation/order/apply/vehicle",

  editPlaningOrder: baseURL + "/operation/order/edit/vehicle/or/driver",

  add: baseURL + "/operation/order/add",
  edit: baseURL + "/operation/order/edit",
  editReserve: baseURL + "/operation/order/edit/reserve",
  delete: baseURL + "/operation/order/delete",
};
export const planning = {
  getAll: baseURL + "/operation/planning/get/all",
  getFilterAll: baseURL + "/operation/planning/get/filter/all",
  getSingle: baseURL + "/operation/planning/get/single",
  getStatusAll: baseURL + "/operation/planning/get/general/status/all",
  getTypeAll: baseURL + "/operation/planning/get/general/type/all",
  add: baseURL + "/operation/planning/add",
  edit: baseURL + "/operation/planning/edit",
  changeVehicle: baseURL + "/operation/planning/set/vehicle",
  setState: baseURL + "/operation/planning/set/state",
  setStateWarning: baseURL + "/operation/planning/set/state/warning",
  delete: baseURL + "/operation/planning/delete",
  getVehiceStatus: baseURL + "/operation/planning/get/vehicle/status",
  readFile: baseURL + "/operation/planning/read/file",
  setStateTonnageAndKilometer:
    baseURL + "/operation/planning/set/state/tonnage/and/kilometer",
  setApprovalFiles: baseURL + "/operation/planning/add/documents",
  getApprovalFiles: baseURL + "/operation/planning/get/documents",
  transition: {
    add: baseURL + "/operation/planning/transition/add",
    edit: baseURL + "/operation/planning/transition/edit",
    delete: baseURL + "/operation/planning/transition/delete",
  },

  getPlanningInfo: baseURL + "/operation/planning/get/completed/planning/info",

  excelExport: baseURL + "/operation/planning/excel/export",
};

export const routePlanner = {
  calculate: baseURL + "/route/planner/calculate",
};
