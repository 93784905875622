<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <addModal ref="addItem" @refresh="getAll()" />
    <editModal @refresh="getAll()" :detail="selected" />

    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <div class="w-full pt-1">
        <div class="md:flex items-center justify-between">
          <div class="w-3/12 text-left">
            <div class="w-full inline-block">
              <asyncBtn
                icon="fas fa-plus "
                text="Yasak Ekle"
                class="w-36 btn"
                @click="$refs.addItem.show()"
              />
            </div>
          </div>

          <div class="w-3/12">
            <searchInput
              :isFull="true"
              v-model="search"
              @searcing="() => getAll(true)"
            />
          </div>
        </div>

        <div
          :class="{
            'fixed top-0 w-full z-50 left-0 h-full bg-white': isFullScreen,
          }"
        >
          <div
            :class="{
              'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4':
                !isFullScreen,
              'w-full overflow-y-scroll h-full pb-16': isFullScreen,
            }"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead
                class="bg-white border-black border-dotted border-b-2 border-opacity-20"
              >
                <tr>
                  <th scope="col" class="table-th text-left">
                    Varlık
                  </th>
                  <th
                    scope="col"
                    class="table-th text-center"
                    style="min-width: 120px !important"
                  >
                    İşlem
                    <tableResizeBtn v-model="isFullScreen" />
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y-2 divide-gray-500"
                style="background-color: #3a3a3a"
              >
                <tr v-for="(item, index) in List" :key="index">
                  <td class="table-td text-left">
                    {{ item.name }}
                  </td>
                  <td class="table-td text-center">
                    <button
                      class="process-btn mr-2"
                      @click="showEditModal(item)"
                    >
                      <i class="fas fa-pen"></i>
                    </button>
                    <button class="process-btn" @click="deleteItem(item)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
                <tableLoader :load="load" :length="List.length" colspan="10" />
              </tbody>
            </table>
          </div>
          <div :class="isFullScreen && 'absolute bottom-0 w-full bg-white '">
            <PaginationComponents
              v-if="totalCount > 0"
              :totalRowCount="totalCount"
              :currentPage="currentPage + 1"
              :pageCount="pageCount"
              @changePage="(r) => (currentPage = r - 1)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import searchInput from "@/components/general/search-input.vue";
import tableResizeBtn from "@/components/general/table.resize.btn.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// Netoworking
import axios from "axios";
import { banned } from "@/networking/urlmanager";

// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";
export default {
  name: "banned-module",
  components: {
    PaginationComponents,
    searchInput,
    tableLoader,
    tableResizeBtn,
    asyncBtn,
    addModal,
    editModal,
  },
  data() {
    return {
      isFullScreen: false,
      load: false,
      search: "",
      selected: { id: "" },

      List: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
    };
  },
  methods: {
    showEditModal(item) {
      this.selected = item;
      this.$modal.show("edit-bans-modal");
    },
    getAll(isSearch = false) {
      if (isSearch) this.currentPage = 0;

      this.List = [];
      this.load = true;
      axios
        .get(
          banned.getAll +
            "?page=" +
            this.currentPage +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Ürünü silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              banned.delete,
              {
                rowId: item.id.toString(),
                name: item.name,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
