import axios from "axios";
import { general } from "../networking/urlmanager";

export const getCurrencires = async (currCodes, token) => {
  try {
    const res = await axios.post(
      general.getCurrencires,
      { currCodes },
      {
        headers: {
          Authorization: "Bareer " + token,
        },
      }
    );

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

export const convertTRYToCurrencies = (currencies, tryAmount) => {
  const result = {};
  for (const [code, data] of Object.entries(currencies)) {
    result[code] = tryAmount / data.selling;
  }

  return result;
};

export const convertTRYToCurrenciesCall = async (
  currCodes,
  token,
  tryAmount
) => {
  const currencies = await getCurrencires(currCodes, token);

  const result = {};
  for (const [code, data] of Object.entries(currencies)) {
    result[code] = tryAmount / data.selling;
  }

  return result;
};
