<template>
  <modal
    name="edit-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <logModule :moduleId="3" :rowId="rowId" />
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex items-center justify-center"
    >
      <h4 class="inline-block w-10/12">
        {{ $t("operations.planning.modal.editTitle") }}
      </h4>
      <div class="mt-4 w-2/12 ml-auto flex items-center justify-end">
        <div class="w-6/12">
          <logBtn />
        </div>
        <div class="w-2/12">
          <button @click="$modal.hide('edit-plan-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full flex items-center justify-center my-2 bg-gray-200 h-12">
      <button
        class="w-6/12 border-r"
        @click="activeMenu = 1"
        :class="activeMenu == 1 && 'bg-red-600 text-white h-full'"
      >
        GENEL
      </button>
      <button
        class="w-6/12"
        @click="activeMenu = 2"
        :class="activeMenu == 2 && 'bg-red-600 text-white h-full'"
      >
        DOSYALAR
      </button>
    </div>

    <form
      v-if="activeMenu == 1"
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            v-model="orderNo"
            :disabled="true"
            title="Sipariş Numarası"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="text"
            minlength="1"
            maxlength="50"
            :required="true"
            :disabled="true"
            v-model="orderName"
            title="Sipariş Adı"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            :type="
              orderDate.split(' ').length === 1 ? 'date' : 'datetime-local'
            "
            minlength="1"
            :required="true"
            v-model="orderDate"
            :disabled="true"
            title="Sipariş Tarihi"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="complateDate"
            :disabled="true"
            title="Kapanış Tarihi"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            :required="true"
            v-model="customer"
            :disabled="true"
            title="Müşteri"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :title="$t('operations.planning.modal.billedCompany')"
            v-model="invoicedCompany"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            title="Gönderici"
            :disabled="true"
            v-model="receivedName"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            :disabled="true"
            type="text"
            title="Alıcı"
            v-model="deliveredName"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            v-model="productType"
            :required="true"
            :disabled="true"
            title="Mal Sınıfı"
          />
        </div>
        <div class="w-full mt-4 md:w-4/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            v-model="amount"
            :disabled="true"
            title="Miktar"
          />
        </div>
        <div class="w-full mt-4 md:w-2/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            title="Birim"
            :disabled="true"
            v-model="unitType"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12">
          <diffInput
            type="datetime-local"
            v-model="fillingDate"
            :max="deliveryDate"
            :min="getMinFillingDate"
            :required="true"
            :disabled="isDisabled"
            :title="$t('operations.planning.modal.fillingTime')"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            :min="fillingDate"
            type="datetime-local"
            v-model="deliveryDate"
            :required="true"
            :disabled="isDisabled"
            :title="$t('operations.planning.modal.deliveryTime')"
          />
        </div>
      </div>

      <div class="w-full mt-4">
        <waypoints v-model="waypoints" :hiddenInsert="true" />
      </div>

      <div class="w-full mt-4">
        <routeViewiver
          v-if="
            // changeLocationState &&
            fillingPoint.lat > 0 &&
            fillingPoint.lng > 0 &&
            deliveryPoint.lat > 0 &&
            deliveryPoint.lng > 0
          "
          :vehicle="this.vehicle"
          ref="routerViev"
          :customer="receivedName"
          :outpoint="deliveredName"
          :center="{
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :startPosition="{
            adress: fillingPoint.adress,
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :waypointsList="waypoints"
          @changeStartPosition="(val) => (fillingPoint = val)"
          @changeEndPosition="(val) => (deliveryPoint = val)"
          @changeOutpointId="(r) => (receivedName = r)"
          @changeCustomerId="(r) => (deliveredName = r)"
          @changePolyline="(r) => changePolyline(r)"
          :endPosition="{
            adress: deliveryPoint.adress,
            lat: Number(deliveryPoint.lat),
            lng: Number(deliveryPoint.lng),
          }"
          :height="'200px'"
          @change="(r) => (costDistribution = r)"
        />
      </div>

      <div class="md:flex mt-5">
        <div class="w-full md:w-full relative">
          <diffInput
            type="text"
            :required="true"
            title="Araç"
            v-model="vehicle"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12 relative">
          <diffInput
            type="text"
            v-model="dorse"
            :required="true"
            :disabled="true"
            :title="$t('operations.planning.modal.trailer')"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            v-model="personnel"
            :required="true"
            :disabled="true"
            :title="$t('operations.planning.modal.driver')"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="number"
            v-model="hakedisKilometer"
            :required="true"
            :disabled="true"
            title="Tek yön Kilometresi"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
          <diffInput
            type="number"
            :required="true"
            :disabled="isDisabled"
            title="Ay içi Sefer Sayısı"
            :max="2"
            :valCount="50"
            v-model="transitionCount"
            :min="1"
            :step="1"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="text"
            :value="numberFormatter(hakedisKilometer * transitionCount * 2)"
            :required="true"
            :disabled="true"
            title="Toplam Kilometre"
          />
        </div>
      </div>

      <div class="mt-4 md:flex items-center">
        <!-- Yakıt Maliyeti -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <span class="text-sm">Yakıt Maliyeti</span>
          <div>
            <div>
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                class="mb-3"
                :disabled="true"
                :value="numberFormatter(totalFuelPrice) + ' ₺'"
              />
            </div>

            <div class="flex justify-between gap-x-2" v-if="currencies">
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(totalFuelPrice, 'EUR') + '€'"
              />

              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(totalFuelPrice, 'USD') + '$'"
              />
            </div>
          </div>
        </div>

        <!-- Diğer Masraflar -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
          <span class="text-sm">Diğer Masraflar</span>
          <div>
            <div>
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                class="mb-3"
                :disabled="true"
                :value="numberFormatter(otherExpensePrice) + ' ₺'"
              />
            </div>

            <div class="flex justify-between gap-x-2" v-if="currencies">
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(otherExpensePrice, 'EUR') + '€'"
              />

              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(otherExpensePrice, 'USD') + '$'"
              />
            </div>
          </div>
        </div>

        <!-- Ton Maliyeti -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <span class="text-sm">Ton Maliyeti</span>
          <div>
            <div>
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                class="mb-3"
                :disabled="true"
                :value="numberFormatter(tonnagePrice) + ' ₺'"
              />
            </div>

            <div class="flex justify-between gap-x-2" v-if="currencies">
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(tonnagePrice, 'EUR') + '€'"
              />

              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(tonnagePrice, 'USD') + '$'"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 md:flex items-center">
        <!-- Otoyol Maliyeti -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <span class="text-sm">{{
            $t("operations.planning.modal.tollHighwayCost")
          }}</span>
          <div>
            <div>
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                class="mb-3"
                @click="$modal.show('add-hgs-detail-modal')"
                :disabled="true"
                :value="
                  numberFormatter(
                    costDistribution.cost.cash * transitionCount * 2
                  ) + ' ₺'
                "
              />
            </div>

            <div class="flex justify-between gap-x-2" v-if="currencies">
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                @click="$modal.show('add-hgs-detail-modal')"
                :value="
                  formatCurrency(
                    costDistribution.cost.cash * transitionCount * 2,
                    'EUR'
                  ) + '€'
                "
              />

              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                @click="$modal.show('add-hgs-detail-modal')"
                :value="
                  formatCurrency(
                    costDistribution.cost.cash * transitionCount * 2,
                    'USD'
                  ) + '$'
                "
              />
            </div>
          </div>
        </div>

        <!-- Maliyet Tutarı -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
          <span class="text-sm">Maliyet Tutarı</span>
          <div>
            <div>
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                class="mb-3"
                :disabled="true"
                :value="numberFormatter(expensivePrice) + ' ₺'"
              />
            </div>

            <div class="flex justify-between gap-x-2" v-if="currencies">
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(expensivePrice, 'EUR') + '€'"
              />

              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="text"
                :disabled="true"
                :value="formatCurrency(expensivePrice, 'USD') + '$'"
              />
            </div>
          </div>
        </div>

        <!-- Gerçek Satış Fiyatı -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <span class="text-sm">Gerçek Satış Fiyatı</span>
          <div>
            <div>
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="price"
                class="mb-3"
                v-model="totalPrice"
                :max="50000000"
                :min="1"
                :disabled="true"
                :value="numberFormatter(totalPrice)"
              />
            </div>

            <div class="flex justify-between gap-x-2" v-if="currencies">
              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="price"
                :disabled="true"
                :value="formatCurrency(totalPrice, 'EUR')"
              />

              <diffInput
                :hideContainer="true"
                :hideBorder="true"
                type="price"
                :disabled="true"
                :value="formatCurrency(totalPrice, 'USD')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 md:flex items-center">
        <div
          class="w-full mt-4 md:mt-0"
          :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12'"
        >
          <diffInput
            type="select"
            :optList="[
              {
                id: 1,
                name: 'Eklensin',
              },
              {
                id: 2,
                name: 'Eklenmesin',
              },
            ]"
            v-model="isAmortisman"
            title="Amortisman Maliyeti"
            :disabled="isDisabled"
          />
        </div>
        <div
          class="w-full mt-4 md:mt-0 md:px-2"
          :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12 md:pl-2'"
        >
          <diffInput
            type="select"
            :optList="[
              {
                id: 1,
                name: 'Evet',
              },
              {
                id: 2,
                name: 'Hayır',
              },
            ]"
            v-model="isOneWay"
            title="Tek Yön"
            :disabled="isDisabled"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0" v-if="isOneWay == 1">
          <diffInput
            type="number"
            v-model="dayCount"
            title="Gün Sayısı"
            :max="100"
            :min="1"
            :disabled="isDisabled"
          />
        </div>
      </div>

      <div class="w-full mt-4">
        <diffInput
          type="textarea"
          v-model="explanation"
          :title="$t('general.note')"
          maxlength="500"
          :disabled="isDisabled"
        />
      </div>

      <div class="w-full mt-4 text-right" v-if="!isDisabled">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>

    <div v-else class="h-full p-4" style="height: 80vh">
      <div
        v-if="load"
        class="text-center p-2 h-full flex items-center justify-center"
      >
        <span>
          <i class="fas fa-spinner fa-spin fa-2x"></i>
          <p class="mt-2 text-sm">Yükleniyor</p>
        </span>
      </div>

      <div
        v-for="(item, index) in files"
        :key="item.id"
        class="flex items-center justify-between px-4 border-b py-2 text-lg"
      >
        <h3 class="mb-2 text-gray-700 text-lg">{{ 1 + index }}.Dosya</h3>
        <asyncLink :fileUrl="item.fileUrl" />
      </div>
    </div>
  </modal>
</template>
<script>
// NPM
import polyLine from "google-polyline";

// Global components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";
import asyncLink from "@/components/general/asyncLink.vue";
// Networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";

// Local Components
import routeViewiver from "@/components/devItem/route-generator/index.vue";

// Utils
import { calculator } from "@/utils/cost";
import {
  convertTRYToCurrencies,
  getCurrencires,
} from "../../../../utils/getCurrencies";

// Calculator Viewer
import waypoints from "../../../calculator/components/waypointsCalculator.vue";
import moment from "moment";

export default {
  name: "edit-plan-modal",
  props: ["rowId", "isDisabled"],
  components: {
    diffInput,
    asyncBtn,
    routeViewiver,
    waypoints,
    logModule,
    logBtn,
    asyncLink,
  },
  data() {
    return {
      orderNo: "",
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      productType: "",
      amount: "",
      unitType: "",
      fillingDate: "",
      deliveryDate: "",
      hakedisKilometer: "",
      transitionCount: 1,
      currencies: undefined,
      personnel: "",
      vehicle: "",
      dorse: "",
      driver: "",
      driverTC: "",
      costDistribution: { cost: { cash: 0 } },
      explanation: "",
      order: "",

      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      otherExpensePrice: 0,
      tonnagePrice: 0,
      polyline: "",

      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 0,
      expenseDetail: {},
      waypoints: [],

      files: [],
      activeMenu: 1,
      load: false,
    };
  },
  methods: {
    getDetail() {
      axios
        .get(planning.getSingle + "?rowId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          const {
            orderNo,
            orderName,
            orderDate,
            complateDate,
            customer,
            invoicedCompany,
            receivedName,
            deliveredName,
            productType,
            amount,
            unitType,
            fillingDate,
            deliveryDate,
            kilometer,
            transitionCount,
            driver,
            driverTC,
            vehicle,
            dorse,
            expensePrice,
            totalPrice,
            explanation,
            polyline,
            outpointLat,
            outpointLng,
            outpointAdress,
            targetPointLat,
            targetPointLng,
            targetAdress,
            dayCount,
            isAmortisman,
            isOneWay,
            waypoints,
          } = result.data.data;
          // for calculate
          this.order = result.data.data;

          this.orderNo = orderNo;
          this.orderName = orderName;
          this.orderDate = orderDate;
          this.complateDate = complateDate;
          this.customer = customer;
          this.invoicedCompany = invoicedCompany;
          this.receivedName = receivedName;
          this.deliveredName = deliveredName;

          this.productType = productType;
          this.amount = amount;
          this.unitType = unitType;
          this.fillingDate = fillingDate;
          this.deliveryDate = deliveryDate;
          this.transitionCount = transitionCount;
          this.personnel = driver;
          this.driverTC = driverTC;
          this.vehicle = vehicle;
          this.dorse = dorse;
          this.explanation = explanation;
          this.expensivePrice = expensePrice;
          this.polyline = polyline;
          // this.expenseDetail={};
          this.dayCount = dayCount;
          this.totalPrice = totalPrice;
          this.isAmortisman = isAmortisman;
          this.isOneWay = isOneWay;

          this.costDistribution = { cost: { cash: 0 } };
          this.fillingPoint = {
            lat: parseFloat(outpointLat),
            lng: parseFloat(outpointLng),
            adress: outpointAdress,
          };

          this.deliveryPoint = {
            lat: parseFloat(targetPointLat),
            lng: parseFloat(targetPointLng),
            adress: targetAdress,
          };

          const list = JSON.parse(waypoints);

          this.waypoints = list.map((item) => {
            return {
              date: item.date,
              no: item.no,
              amount: item.amount,
              position: {
                adress: item.adress,
                lat: Number(item.latitude),
                lng: Number(item.longitude),
              },
            };
          });

          this.hakedisKilometer = kilometer;
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        });
    },

    formatCurrency(amount, currencyCode, decimals = 2) {
      if (!amount || !this.currencies) return "0";

      const converted = convertTRYToCurrencies(this.currencies, amount);
      const value = converted[currencyCode];

      return value.toFixed(decimals);
    },

    resetAll() {
      this.orderNo = "";
      this.orderName = "";
      this.orderDate = "";
      this.complateDate = "";
      this.customer = "";
      this.invoicedCompany = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.fillingPoint = { lat: 0, lng: 0, adress: "" };
      this.deliveryPoint = { lat: 0, lng: 0, adress: "" };
      this.productType = "";
      this.amount = "";
      this.unitType = "";
      this.fillingDate = "";
      this.deliveryDate = "";
      this.hakedisKilometer = "";
      this.transitionCount = 1;
      this.personnel = "";
      this.driverTC = "";
      this.vehicle = "";
      this.dorse = "";
      this.costDistribution = { cost: { cash: 0 } };
      this.recomendPrice = "";
      this.realSalePrice = "";
      this.explanation = "";
      this.expensivePrice = 0;
      this.polyline = "";
      this.expenseDetail = {};
    },
    save() {
      this.load = true;
      axios
        .post(
          planning.edit,
          {
            rowId: this.rowId,
            orderNo: this.orderNo,
            orderName: this.orderName,
            orderDate: this.orderDate,
            complateDate: this.complateDate,
            customer: this.customer,
            invoicedCompany: this.invoicedCompany,
            receivedName: this.receivedName,
            deliveredName: this.deliveredName,
            productType: this.productType,
            amount: this.amount,
            unitType: this.unitType,
            fillingDate: this.fillingDate,
            deliveryDate: this.deliveryDate,

            vehicle: this.vehicle,
            dorse: this.dorse,
            driver: this.personnel,
            driverTC: this.driverTC,
            kilometer: this.hakedisKilometer,
            expensePrice: this.expensivePrice,
            totalPrice: this.totalPrice,
            explanation: this.explanation,
            polyline: this.polyline,

            outpointAdress: this.fillingPoint.adress,
            outpointLat: this.fillingPoint.lat,
            outpointLng: this.fillingPoint.lng,
            targetpointAdress: this.deliveryPoint.adress,
            targetpointLat: this.deliveryPoint.lat,
            targetpointLng: this.deliveryPoint.lng,

            // expense detail
            tollsPrice: (
              this.costDistribution.cost.cash *
              this.transitionCount *
              2
            ).toFixed(2),
            fuelPrice: this.expenseDetail.fuelTotalPrice.toFixed(2),
            adbluePrice: this.expenseDetail.totalAdbluePrice.toFixed(2),
            whellPrice: this.expenseDetail.totalWhellPrice.toFixed(2),
            engineOilPrice: this.expenseDetail.totalEngineOilPrice.toFixed(2),
            filtersPrice: this.expenseDetail.TotalfilterPrice.toFixed(2),
            brakepadPrice: this.expenseDetail.totalBrakePadPrice.toFixed(2),
            diskPrice: this.expenseDetail.totalDiskPrice.toFixed(2),
            clutchPrice: this.expenseDetail.totalClutchPrice.toFixed(2),
            periodMainstancePrice:
              this.expenseDetail.totalperiodicMaintenanceCost.toFixed(2),
            TrafficInsuranceAndinsurancePrice:
              this.expenseDetail.totalTrafficInsurancePriceAndinsurancePrice.toFixed(
                2
              ),
            otherCost: this.expenseDetail.otherCost.toFixed(2),

            isAmortisman: this.isAmortisman,
            isOneWay: this.isOneWay,
            dayCount: this.dayCount,
            transitionCount: this.transitionCount,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.resetAll();
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-plan-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
    changePolyline(val) {
      this.polyLine = polyLine.encode(val);
    },
    async calculatePrice() {
      let montlyKilometer = this.hakedisKilometer * this.transitionCount * 2;
      let hgsPrice =
        parseFloat(this.costDistribution.cost.cash) * this.transitionCount * 2;
      if (this.isOneWay == 1) {
        montlyKilometer = this.hakedisKilometer * this.transitionCount;
        hgsPrice = parseFloat(this.costDistribution.cost.cash);
      }

      this.expenseDetail = await calculator(
        this.$store.state.userData.token,
        1,
        montlyKilometer * 12,
        this.vehicle,
        this.order,
        montlyKilometer,
        hgsPrice,
        this.isAmortisman == 1,
        this.isOneWay == 1,
        this.dayCount
      );

      const { total, fuelTotalPrice, totalAdbluePrice } = this.expenseDetail;

      this.totalFuelPrice = fuelTotalPrice + totalAdbluePrice;
      this.otherExpensePrice = total - this.totalFuelPrice;

      if (this.isOneWay == 1) {
        this.tonnagePrice =
          (total / montlyKilometer / 27) * this.hakedisKilometer;
      } else {
        this.tonnagePrice =
          (total / montlyKilometer / 27) * this.hakedisKilometer * 2;
      }
      this.expensivePrice = total;
    },
    // changeFillingPoint(item) {
    //   // Kullanılmıyor
    //   this.changeLocationState = false;
    //   this.fillingPoint = item;
    //   this.receivedName = item.adress;
    //   if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
    //     setTimeout(() => {
    //       this.changeLocationState = true;
    //       this.$refs.routerViev.getRoute();
    //     }, 200);
    //   }
    // },
    // changeDeliveryPoint(item) {
    //   // Kullanılmıyor
    //   this.changeLocationState = false;
    //   this.deliveryPoint = item;
    //   this.deliveredName = item.adress;
    //   if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
    //     setTimeout(() => {
    //       this.changeLocationState = true;
    //       this.$refs.routerViev.getRoute();
    //     }, 200);
    //   }
    // },
    // changeProductType(state) {
    //   // Kullanılmıyor
    //   this.changeLocationState = false;
    //   this.explosiveState = state;
    //   if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
    //     setTimeout(() => {
    //       this.changeLocationState = true;
    //       this.$refs.routerViev.getRoute();
    //     }, 200);
    //   }
    // },
    // getTimeDetail(mins) {
    //   // Kullanılmıyor
    //   var h = (mins / 60) | 0,
    //     m = mins % 60 | 0;
    //   return h + " Saat" + " " + m + " Dakika";
    // },
    numberFormatter(val) {
      const formattedValue = new Intl.NumberFormat("tr-TR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(val);
      return formattedValue;
    },

    getFiles() {
      this.load = true;
      axios
        .get(planning.getApprovalFiles + "?planId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          this.files = response.data.data;
          this.load = false;
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
  },
  async created() {
    this.currencies = await getCurrencires(
      ["EUR", "USD"],
      this.$store.state.userData.token
    );
  },
  computed: {
    getMinFillingDate() {
      return moment(this.orderDate).format("YYYY-MM-DD HH:mm");
    },
  },
  watch: {
    async transitionCount() {
      await this.calculatePrice();
    },
    async costDistribution(val) {
      this.hakedisKilometer = val.totalKilometer;
      await this.calculatePrice();
    },
    async isAmortisman() {
      await this.calculatePrice();
    },
    isOneWay() {
      this.calculatePrice();
    },
    dayCount() {
      this.calculatePrice();
    },
    activeMenu(val) {
      if (val == 2) {
        this.files = [];
        this.getFiles();
      }
    },
  },
};
</script>
