import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

//pages

//  user pages and auth pages
import loginPage from "../views/auth/index.vue";
import profilePage from "../views/profile/index.vue";
import newPassword from "../views/auth/newPassword/index.vue";

// general pages
import vehicleTrackingPage from "../views/tracking/index.vue";

// Operations Pages
import ordersPage from "../views/operations/orders/index.vue";
import planningPage from "../views/operations/planning/index.vue";
import collectivePage from "../views/operations/collectivePlanning/index.vue";
import planningOrderPage from "../views/operations/planningOrder/index.vue";
import shipEvacuation from "../views/operations/shipEvacuation/index.vue";
import storageEvacuation from "../views/operations/storageEvacuation/index.vue";

// calculator
import calculatorPage from "../views/calculator/index.vue";

// Vehicle Info
import routeReport from "../views/vehiclesInfo/routeReport/index.vue";
import vehicleKilometers from "../views/vehiclesInfo/vehicleKilometers/index.vue";
import vehicleIdleTimes from "../views/vehiclesInfo/vehicleIdleTimes/index.vue";
import customerWaitingTime from "../views/vehiclesInfo/customerWaitingTime/index.vue";
import driverHomeVisitReport from "../views/vehiclesInfo/driverHomeVisitReport/index.vue";
import washPointReport from "../views/vehiclesInfo/washPointReport/index.vue";
import fuelAnomaliesReport from "../views/vehiclesInfo/fuelAnomaliesReport/index.vue";
import serviceRequestReports from "../views/vehiclesInfo/serviceRequestReports/index.vue";
import routeDeviationReport from "../views/vehiclesInfo/routeDeviationReport/index.vue";
import driverTally from "../views/vehiclesInfo/driverTally/index.vue";
import vehicleReport from "../views/vehiclesInfo/vehicleReport/index.vue";
import trailerSignalSystem from "../views/vehiclesInfo/trailerSignalSystem/index.vue";
import vehicleWaitingReport from "../views/vehiclesInfo/vehicleWaitingReport/index.vue";
import vehicleRepairAndMaintenance from "../views/vehiclesInfo/vehicleRepairAndMaintenance/index.vue";

// Human Resources Pages
import staffPage from "../views/control-panel/human-resources/index.vue";
import productsPage from "../views/control-panel/products/index.vue";
import washPointPage from "../views/control-panel/washPoints/index.vue";
import branchesPage from "../views/control-panel/branches/index.vue";
import restStopsPage from "../views/control-panel/restStops/index.vue";
import customersPage from "../views/control-panel/customers/index.vue";
import usersPage from "../views/control-panel/users/index.vue";
import costingPage from "../views/control-panel/costing/index.vue";
import fleetManagementPage from "../views/control-panel/fleetManagement/index.vue";
import routeZoneDesigner from "../views/control-panel/routeZoneDesigner/index.vue";
import banPage from "../views/control-panel/bans/index.vue";

// errors page
import networkErrorPage from "../views/errors/network-error.vue";
import permErrorPage from "../views/errors/perm-error.vue";
import generalPermErrorPage from "../views/errors/general-perm-error.vue";

const module = {
  vehicleTracking: 1,
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "loginPage",
    component: loginPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/newPassword",
    name: "newPassword",
    component: newPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/vehicle-tracking",
    name: "vehicleTrackingPage",
    component: vehicleTrackingPage,
    meta: {
      requiresAuth: true,
      module: module.vehicleTracking,
      name: "Araç Takip",
    },
  },
  {
    path: "/profile",
    name: "profilePage",
    component: profilePage,
    meta: { requiresAuth: true, name: "Profil" },
  },

  // Operations
  {
    path: "/orders",
    name: "ordersPage",
    component: ordersPage,
    meta: { requiresAuth: true, module: 11, name: "Siparişler" },
  },
  {
    path: "/planning",
    name: "planningPage",
    component: planningPage,
    meta: { requiresAuth: true, module: 3, name: "Planlama" },
  },
  // {
  //   path: "/collectivePlanning",
  //   name: "collectivePlanningPage",
  //   component: collectivePage,
  //   meta: { requiresAuth: true, module: 12, name: "Toplu Planlama" },
  // },
  {
    path: "/planning/orders",
    name: "planningOrder",
    component: planningOrderPage,
    meta: { requiresAuth: true, module: 3, name: "P.Siparişler" },
  },
  {
    path: "/calculator",
    name: "calculatorPage",
    component: calculatorPage,
    meta: { requiresAuth: true, module: 2, name: "Hesap Makinesi" },
  },
  {
    path: "/customers",
    name: "customersPage",
    component: customersPage,
    meta: { requiresAuth: true, module: 4, name: "Müşteriler" },
  },
  {
    path: "/products",
    name: "productsPage",
    component: productsPage,
    meta: { requiresAuth: true, module: 5, name: "Ürünler" },
  },
  // {
  //   path: "/washPoint",
  //   name: "washPointPage",
  //   component: washPointPage,
  //   meta: { requiresAuth: true, module: 9, name: "Yıkama Noktaları" },
  // },
  {
    path: "/branches",
    name: "branchesPage",
    component: branchesPage,
    meta: { requiresAuth: true, module: 14, name: "Şubeler" },
  },
  // {
  //   path: "/restStops",
  //   name: "restStopsPage",
  //   component: restStopsPage,
  //   meta: { requiresAuth: true, module: 15, name: "Dinlenme Noktaları" },
  // },
  // {
  //   path: "/fleetManagement",
  //   name: "fleetManagementPage",
  //   component: fleetManagementPage,
  //   meta: { requiresAuth: true, module: 16, name: "Filo Yönetimi" },
  // },
  {
    path: "/routeZoneDesigner",
    name: "routeZoneDesigner",
    component: routeZoneDesigner,
    meta: { requiresAuth: true, module: 24, name: "Rota Bölge Editörü" },
  },
  // {
  //   path: "/shipEvacuation",
  //   name: "shipEvacuation",
  //   component: shipEvacuation,
  //   meta: { requiresAuth: true, module: 10, name: "Gemi Tahliyesi" },
  // },

  // {
  //   path: "/storageEvacuation",
  //   name: "storageEvacuation",
  //   component: storageEvacuation,
  //   meta: { requiresAuth: true, module: 13, name: "Depo Tahliyesi" },
  // },

  // Reports
  // {
  //   path: "/driverTally",
  //   name: "driverTally",
  //   component: driverTally,
  //   meta: { requiresAuth: true, module: 17, name: "Sürücü Puantajı" },
  // },
  // {
  //   path: "/routeReport",
  //   name: "routeReport",
  //   component: routeReport,
  //   meta: { requiresAuth: true, module: 18, name: "Güzergah Raporu" },
  // },
  // {
  //   path: "/vehicleKilometers",
  //   name: "vehicleKilometers",
  //   component: vehicleKilometers,
  //   meta: { requiresAuth: true, module: 19, name: "Araç Kilometreleri" },
  // },
  // {
  //   path: "/vehicleIdleTimes",
  //   name: "vehicleIdleTimes",
  //   component: vehicleIdleTimes,
  //   meta: {
  //     requiresAuth: true,
  //     module: 20,
  //     name: "Araçların Bekleme Süreleri",
  //   },
  // },
  // {
  //   path: "/customerWaitingTime",
  //   name: "customerWaitingTime",
  //   component: customerWaitingTime,
  //   meta: { requiresAuth: true, module: 21, name: "Bekleme Raporu" },
  // },
  // {
  //   path: "/driverHomeVisitReport",
  //   name: "driverHomeVisitReport",
  //   component: driverHomeVisitReport,
  //   meta: { requiresAuth: true, module: 22, name: "Ziyaret Raporu" },
  // },
  // {
  //   path: "/washPointReport",
  //   name: "washPointReport",
  //   component: washPointReport,
  //   meta: { requiresAuth: true, module: 23, name: "Yıkama  Raporu" },
  // },
  // {
  //   path: "/fuelAnomaliesReport",
  //   name: "fuelAnomaliesReport",
  //   component: fuelAnomaliesReport,
  //   meta: { requiresAuth: true, module: 25, name: "Yakıt Anomalileri  Raporu" },
  // },
  {
    path: "/serviceRequestReports",
    name: "serviceRequestReports",
    component: serviceRequestReports,
    meta: { requiresAuth: true, module: 26, name: "Servis İstek  Raporu" },
  },
  {
    path: "/routeDeviationReport",
    name: "routeDeviationReport",
    component: routeDeviationReport,
    meta: { requiresAuth: true, module: 16, name: "Araç Kilometre" },
  },
  {
    path: "/vehicleReport",
    name: "vehicleReport",
    component: vehicleReport,
    meta: { requiresAuth: true, module: 17, name: "Araç Raporu" },
  },
  {
    path: "/trailerSignalSystem",
    name: "trailerSignalSystem",
    component: trailerSignalSystem,
    meta: { requiresAuth: true, module: 18, name: "Dorse Performans" },
  },
  {
    path: "/vehicleWaitingReport",
    name: "vehicleWaitingReport",
    component: vehicleWaitingReport,
    meta: { requiresAuth: true, module: 19, name: "Araç Bekleme" },
  },

  {
    path: "/vehicleRepairAndMaintenance",
    name: "vehicleRepairAndMaintenance",
    component: vehicleRepairAndMaintenance,
    meta: { requiresAuth: true, module: 20, name: "Araç Bakım Onarım" },
  },

  // Human Resources Pages
  {
    path: "/staff",
    name: "staffPage",
    component: staffPage,
    meta: { requiresAuth: true, module: 6, name: "Sürücüler" },
  },

  // Control Panel
  {
    path: "/users",
    name: "usersPage",
    component: usersPage,
    meta: { requiresAuth: true, module: 7, name: "Kullanıcılar" },
  },
  {
    path: "/ban/country",
    name: "bansPage",
    component: banPage,
    meta: { requiresAuth: true, module: 7, name: "Yasaklamalar" },
  },
  {
    path: "/management/costing",
    name: "managementCostingPage",
    component: costingPage,
    meta: { requiresAuth: true, module: 8, name: "Maliyet Kalemleri" },
  },

  // error pages
  {
    path: "/network-error",
    name: "network-error",
    component: networkErrorPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/permission-error",
    name: "permission-error",
    component: permErrorPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/general-perm-error",
    name: "general-perm-error",
    component: generalPermErrorPage,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.state.isAuth
  ) {
    next("/");
  } else {
    if (store.state.isAuth) {
      if (
        to.matched[0].meta.module
          ? store.state.userData.perms.some(
              (r) =>
                r.editState != 0 && r.permtypeId == to.matched[0].meta.module
            )
          : true
      ) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
});

export default router;
