<template>
  <modal
    name="vehicle-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
  >
    <!-- Modal Header -->
    <div
      class="pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"
    >
      <h4 class="text-xl font-semibold">ÖNERİLEN ARAÇ DETAYI</h4>
      <button
        type="button"
        class="px-3 text-lg"
        @click="$modal.hide('vehicle-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Modal Content -->
    <div
      class="pb-4 px-4 pt-2 xl:flex items-start justify-between 2xl:mb-20 mb-8"
      :style="{ height: '60vh' }"
    >
      <!-- Map Section -->
      <div class="w-full xl:w-1/2 mb-4 xl:mb-0">
        <mapItem v-if="detail" :item="detail" height="35vh" />
        <div class="mt-9">
          <label class="block font-bold text-md mb-2"
            >Muayene Bitiş Tarihleri</label
          >

          <RecommendationDetail
            label="Araç Muayene Tarihi"
            :value="
              formattedEndDate(
                detail?.recomendation?.vehicleInspectionEndDate
                  ? detail?.recomendation?.vehicleInspectionEndDate
                  : detail?.vehicleInspectionEndDate
              )
            "
            :date="true"
          />
          <RecommendationDetail
            label="Dorse Muayene Tarihi"
            :date="true"
            :value="
              formattedEndDate(
                detail?.recomendation?.inspectionEndDate
                  ? detail?.recomendation?.inspectionEndDate
                  : detail?.inspectionEndDate
              )
            "
          />
          <RecommendationDetail
            label="Araç ADR Muayene Tarihi"
            :value="
              formattedEndDate(
                detail?.recomendation?.vehicleAdrEndDate
                  ? detail?.recomendation?.vehicleAdrEndDate
                  : detail?.vehicleAdrEndDate
              )
            "
            :date="true"
          />
          <RecommendationDetail
            label="Dorse ADR Muayene Tarihi"
            :value="
              formattedEndDate(
                detail?.recomendation?.dorseAdrEndDate
                  ? detail?.recomendation?.dorseAdrEndDate
                  : detail?.dorseAdrEndDate
              )
            "
            :date="true"
            :lastItem="true"
          />
        </div>
      </div>

      <!-- Recommendation Details -->
      <div class="w-full xl:w-1/2 pb-4 px-4 space-y-2">
        <label class="block font-bold text-lg mb-2">ÖNERİ NEDENLERİ</label>
        <RecommendationDetail label="Araç Sahipliği" :value="vehicle()" />
        <RecommendationDetail
          label="Yükleme Noktasına Yakınlık"
          :value="`${detail?.recomendation?.distancePriority} KM`"
        />

        <RecommendationDetail
          label="Müşteri Önceliği"
          :value="detail?.recomendation?.customerPriority"
        />

        <RecommendationDetail
          label="Araç Kapasitesi"
          :value="
            detail?.recomendation?.capacity === '0.00'
              ? 26
              : detail?.recomendation?.capacity | formatCapacity
          "
        />

        <RecommendationDetail
          label="Yük Miktarı"
          :value="detail?.tonnage | formatCapacity"
        />

        <RecommendationDetail
          label="Araç Muayene Bitiş Tarihi"
          :iconClass="
            detail?.recomendation?.isInspectionAvailable
              ? 'fas fa-check text-green-500'
              : 'fas fa-times text-red-500'
          "
        />

        <RecommendationDetail
          label="Araç Müsaitliği"
          :iconClass="
            detail?.recomendation?.isVehicleAvailable
              ? 'fas fa-check text-green-500'
              : 'fas fa-times text-red-500'
          "
        />

        <!-- <RecommendationDetail
          label="Dorse / Ürün Uygunluğu"
          :iconClass="
            detail?.recomendation?.dorseAvailable
              ? 'fas fa-check text-green-500'
              : 'fas fa-times text-red-500'
          "
        /> -->

        <RecommendationDetail
          label="ADR Gereksinimini Karşılıyor mu?"
          :iconClass="
            detail?.recomendation?.isADRCompatible
              ? 'fas fa-check text-green-500'
              : 'fas fa-times text-red-500'
          "
        />

        <!-- <RecommendationDetail
          label="Son Yük Uygunluğu?"
          title="Aracın taşıdığı son yük mevcut yük ile aynı mı veya daha önce hiç ürün taşımadı mı"
          :iconClass="
            detail?.recomendation?.lastProductAvailable === 1
              ? 'fas fa-check text-green-500'
              : detail?.recomendation?.lastProductAvailable === 2
              ? 'fas fa-times text-red-500'
              : 'fas fa-exclamation-triangle text-yellow-500'
          "
        /> -->

        <!-- <RecommendationDetail
          label="Yıkama Zorunluluğu Var mı?"
          :iconClass="
            detail?.recomendation?.isWashing == 2
              ? 'fas fa-check text-green-500'
              : detail?.recomendation?.isWashing == 3
              ? 'fas fa-exclamation-triangle text-yellow-500'
              : 'fas fa-times text-red-500'
          "
        /> -->

        <RecommendationDetail
          v-if="detail.orderAmount <= 30"
          label="Araç Tonajı Uygun Mu?"
          :iconClass="
            detail?.recomendation?.isTonnageSuitable
              ? 'fas fa-check text-green-500'
              : 'fas fa-times text-red-500'
          "
          :lastItem="true"
        />
      </div>
    </div>
  </modal>
</template>

<script>
//npm
import moment from "moment";

//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// local components
import mapItem from "./map.vue";
import RecommendationDetail from "./recommendationDetail.vue";
export default {
  name: "vehicle-modal",
  props: ["detail"],
  components: {
    RecommendationDetail,
    diffInput,
    asyncBtn,
    mapItem,
  },
  data() {
    return {
      customer: "",
      hakedisKilometer: "",
    };
  },

  methods: {
    formattedEndDate(val) {
      return val ? moment(val).format("DD/MM/YYYY") : "-";
    },
    vehicle() {
      if (
        this.detail?.vehicle?.isRent === 1 ||
        this.detail?.haveType === 1 ||
        this.detail?.isRent === 1
      ) {
        return "Kiralık ";
      } else if (
        this.detail?.vehicle?.isRent === 2 ||
        this.detail?.haveType === 2 ||
        this.detail?.isRent === 2
      ) {
        return "Öz Mal";
      } else {
        return "-";
      }
    },
  },
  filters: {
    formatCapacity(capacity) {
      if (typeof capacity === "number" && capacity >= 1000) {
        return capacity / 1000 + " TON";
      } else {
        return capacity + " TON";
      }
    },
  },
};
</script>
